import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "../node_modules/react-router-dom";
import { AppContextProvider } from './context/AppContext';

import Loader from './components/loader/Loader';

/* Styles */
import './App.css';

/* Components */
import Menu from './components/menu/Menu';
import Home from './components/home/Home';
// import Login from './components/login/Login';
import Recovery from './components/login/recovery/Recovery';
import Deeplink from './components/deeplink/Deeplink';
import Logout from './components/login/logout/Logout';
import Register from './components/register/Register';
import Legal from './components/register/legal/Legal';
// import LandingForm from "./components/landingForm/LandingForm";
// import Consult from "./components/consultCC/Consult";
// import NotFound from "./components/notfound/NotFound";

const App = () => {

  const [isLogged, setLogged] = useState(false);
  const [isDeepLink, setDeepLink] = useState(false);

  const changeLogged = (logged) => {
    localStorage.setItem("isLogged", logged);
    setLogged(logged);
  }

  useEffect(() => {
    if (window.location.pathname.includes("ux")) {
      if (window.location.pathname.split("/").pop() === "ux") {
        // console.log("join1")
        window.location.href = "https://avanzo.co/registrarme-en-avanzo/";
      }
    } else if (window.location.pathname.split("/").pop() === "registro") {
      // console.log("join2")
      window.location.href = "https://avanzo.co/registrarme-en-avanzo/";
    }

    if (localStorage.isLogged !== undefined && !isLogged) {
      setLogged(localStorage.isLogged);
    }

    if (window.location.href.indexOf("/upload_request_documents") !== -1) {
      setDeepLink(true);
    }

    if (window.location.href.indexOf("/upload_documents") !== -1) {
      localStorage.clear();
      setDeepLink(true);
    }
  }, [isLogged]);

  if (isLogged) {

    return (
      <AppContextProvider>
        <Loader />
        <Router>
          <Menu changeLogged={changeLogged} />
          <Switch>
            <Route exact path="/:identificationId">
              <Home />
            </Route>
            {/* esto se comenta */}
            {/* <Route exact path="/ux/:identificationId">
              <Home />
            </Route> */}

            {isDeepLink && <Route path="/upload_request_documents/:token" render={(props) => <Deeplink {...props} isRequest={true} />}>
            </Route>}
            {isDeepLink && <Route path="/upload_documents/:token">
              {localStorage.clear()}
              {window.location.reload()}
            </Route>}
            <Route path="/logout" render={(props) => <Logout {...props} />} />
          </Switch>
        </Router>
      </AppContextProvider>
    );
  } else {
    return (
      <AppContextProvider>
        <Loader />
        <Menu changeLogged={changeLogged} />
        <Router>
          <Switch>
            {!isDeepLink && <Route exact path="/:identificationId">
              {/* <Redirect to={"/registro"} /> */}
              <Register />
            </Route>}
            {/* <Redirect to={"/registro"} /> */}

            {/* esto se comenta */}
            {/* {!isDeepLink && <Route exact path="/ux/:identificationId">
              <Register />
            </Route>} */}
            {isDeepLink && <Route path="/upload_documents/:token" render={(props) => <Deeplink {...props} isRequest={false} />} />}
            {isDeepLink && <Route path="/upload_request_documents/:token" render={(props) => <Deeplink {...props} isRequest={true} />} />}
            <Route exact path="/">
              <Redirect to={"/registro"} />
            </Route>
            {
              isDeepLink && <Route exact path="/">
                <Redirect to={"/registro"} />
              </Route>
            }
            <Route exact path="/recuperar-contrasena">
              <Recovery />
            </Route>
            <Route path="/registro" >
              <Register />
            </Route>
            <Route path="/legal/:url" >
              <Legal />
            </Route>
            {/* <Route exact path="/login">
              <Login changeLogged={changeLogged} />
            </Route> */}
            {/* <Route path="/newRegister" >
              <LandingForm />
            </Route> */}
            {/* <Route path="/registro" >
              <Consult />
            </Route> */}
            {/* <Route exact>
              <NotFound />
            </Route> */}
          </Switch>
        </Router>
      </AppContextProvider>
    );
  }

}

export default App;
