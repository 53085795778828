import React, { useEffect } from "react";
import pdfPlus from '../../../assets/pdfs/AVANZO_PLUS.pdf'
import pdfExpress from '../../../assets/pdfs/AVANZO_EXPRESS.pdf'

// components AntD
import { Row, Col, Button } from "antd";

// styles 
import "./Product.css";
import baseUrl, { env } from "./../../../services/BaseUrl";
import imgPrestamo from '../../../assets/img/prestamo.png';
import imgExpress from '../../../assets/img/express.png';
import { useHistory } from "react-router-dom";

const Product = (props) => {

    const [isSelected, setIsSelected] = React.useState(null);
    const history = useHistory();

    // console.log(isSelected)

    // Arreglo de Productos 
    const products = [
        {
            id: 1,
            name: 'Avanzo Plus',
            description: <h2>Cupo de crédito de hasta $500.000 <br />  Disponible <b>desde tu segundo mes</b></h2>,
            image: imgPrestamo,
            vehicleData: false,
            active: props.result >= 60 ? true : false,
        },
        {
            id: 3,
            name: 'Avanzo Express',
            description: <h2>Cupo de crédito de hasta $150.000 <br />  Disponible <b>desde tu primer semana</b></h2>,
            image: imgExpress,
            vehicleData: false,
            active: props.result >= 6 && props.result <= 59 ? true : false,
        },
        // {
        //     id: 2,
        //     name: 'SOAT',
        //     description: 'Te financiamos el SOAT de tu vehículo para que avances seguro y sin preocupaciones.',
        //     image: imgSoat,
        //     vehicleData: true
        // },
    ]

    useEffect(() => {
        if (typeof props.formData.product_id === 'undefined') {
            products.forEach((product, index) => {
                if (product.active) {
                    selectProduct(index);
                }
            });
        } else {
            products.forEach((product, index) => {
                if (product.active) {
                    setIsSelected(index);
                }
            });
        }
    }, [props.result]);


    const selectProduct = (ind) => {
        props.selectProduct(products[ind].id, products[ind].name, products[ind].vehicleData);
        // props.stepUp(); 
        setIsSelected(ind);
    }

    const seeDetail = (index) => {
        if (index === 1) {
            window.open(pdfPlus, "_blank");
        } else if (index === 3) {
            window.open(pdfExpress, "_blank");
        }
    }

    const backToLogin = () => {
        // console.log(history)
        if (env === "test" || env === "dev") {
            history.push("/login")
            window.location.reload();
        } else if (env === "prod") {
            window.location.href = baseUrl.replace("back", "registro");
        }
    }

    return (
        <div className="product">
            <Row gutter={24} className="mb-20">
                <Col xs={24}>
                    <h1>Tu <b>NUEVA SOLICITUD</b> debe ser aplicada a alguno de estos productos elige el que aplica a tu perfil</h1>
                    {/* <h6>Elige uno de los productos que tenemos para ti</h6> */}
                </Col>
            </Row>
            <Row gutter={8} className="mt-20 product-container">
                {
                    products.map((product, index) => {
                        if (product.active) {
                            return (
                                <div className='contentProduct' key={index}>
                                    <div className={"product-box " + (isSelected === index ? "active" : "")} key={index} onClick={() => selectProduct(index)}>
                                        {/* <img src={isSelected === index ? product.imageActive : product.image} alt={product.name} /> */}
                                        <img src={product.image} alt={product.name} />
                                        <h3 style={{ fontSize: '24px' }}>{product.name}</h3>
                                        <h4>{product.description}</h4>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <br />
                                        <br />
                                        <Button onClick={() => seeDetail(product.id)} className="button-req" style={{ backgroundColor: '#179ad7', color: 'white' }} >
                                            Ver detalle
                                        </Button>
                                    </div>

                                    {/* Disenio anterior [NO BORRAR] */}
                                    {/* <div className={"product-box"} >
                                        <img src={product.image} alt={product.name} />
                                    </div>
                                    <div className="text">
                                        {product.description}
                                        <br />
                                        <Button onClick={() => selectProduct(index)} className="button-req"><b>Pedir mi cupo ¡YA!</b></Button>
                                        <br />
                                        <br />
                                        <Button onClick={() => seeDetail(product.id)} className="button-req" style={{ backgroundColor: '#179ad7', color: 'white' }} >
                                            Ver detalle
                                        </Button>
                                    </div> */}
                                </div>
                            )
                        }
                    })
                }

                {
                    (parseInt(localStorage.diffDays) < 5 || localStorage.diffDays === null) && (<div className='contentProducts' style={{ textAlign: 'center' }}>
                        <h1 style={{ color: "red", fontSize: "28px" }}>Algo salio mal</h1>
                        <h2>No dispones del tiempo necesario para solicitar uno de nuestros productos</h2>
                    </div>)
                }

                {
                    localStorage.isRegistered && (<div className='contentProducts' style={{ textAlign: 'center' }}>
                        <br />
                        <h1 style={{ color: "red", fontSize: "28px" }}>Eres un usuario registrado</h1>
                        <h2>Por favor ingresa a la plataforma y realiza tu solicitud desde allí</h2>
                        <br />
                        <Button onClick={backToLogin} className="button-req" style={{ backgroundColor: '#179ad7', color: 'white' }} >
                            Ingresar
                        </Button>
                    </div>)
                }

                {
                    localStorage.isPreRecord === "false" && (<div className='contentProducts' style={{ textAlign: 'center' }}>
                        <br />
                        <h1 style={{ color: "red", fontSize: "28px" }}>Aun no estas registrado</h1>
                        <h2>Para solicitar uno de nuestros productos debes realizar un preregistro</h2>
                        <br />
                        <Button onClick={() => window.location.href = "https://avanzo.co/registrarme-en-avanzo/"} className="button-req" style={{ backgroundColor: '#179ad7', color: 'white' }} >
                            Pre registrarme
                        </Button>
                    </div>)
                }
            </Row >
        </div >

    );
}

export default Product;