import React from "react";

export const AppContext = React.createContext();

export const AppContextProvider = (props) =>{

    const [loader, setLoader] = React.useState(false);
    return(
        <AppContext.Provider value={{loader, setLoader}}>
        {props.children}
        </AppContext.Provider>
    );
}

