import React, { useEffect } from "react";
import moment from 'moment';

//style
import './Personal.css';

// components Antd
import { Row, Col, Input, Select, Modal } from 'antd';



// constants
const { Option } = Select;

const meses = [
    { value: '01', label: 'Enero' },
    { value: '02', label: 'Febrero' },
    { value: '03', label: 'Marzo' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Mayo' },
    { value: '06', label: 'Junio' },
    { value: '07', label: 'Julio' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Septiembre' },
    { value: '10', label: 'Octubre' },
    { value: '11', label: 'Noviembre' },
    { value: '12', label: 'Diciembre' },
];

const Personal = (props) => {

    useEffect(() => {

        if (props.formData.birthday.length === 2 && props.formData.birthmonth.length === 2 && props.formData.birthyear.length === 4) {
            props.setFormData({ ...props.formData, birthDate: props.formData.birthyear + '-' + props.formData.birthmonth + '-' + props.formData.birthday });
        }

    }, [props.formData.birthday, props.formData.birthmonth, props.formData.birthyear]);

    useEffect(() => {

        if (typeof props.formData.birthDate !== 'undefined') {
            let fechaSel = moment(props.formData.birthDate, 'YYYY-MM-DD');
            // console.log("añoSEl", fechaSel)

            let today = moment();

            let diferencias = today.diff(fechaSel, 'years')
            console.log("diferencias", diferencias)
            if (diferencias < 18) {
                Modal.error({ title: 'Fecha de nacimiento inválida', content: 'Solo pueden solicitar crédito personas mayores de 18 años' });
                props.setFormData({ ...props.formData, birthDate: undefined })
            }
        }

    }, [props.formData.birthDate]);


    const handleChange = (value, name) => {

        if (typeof value.target !== 'undefined') {
            props.setFormData({ ...props.formData, [name]: value.target.value });
        } else {
            props.setFormData({ ...props.formData, [name]: value });
        }
    }

    return (
        <div className="personal">
            <Row gutter={24} className="mb-20">
                <Col xs={24}>
                    <h1>Estas creando <b>tu cuenta personal</b>, con ella podras
                        disfrutar de nuestros productos en cualquier momento y
                        consultar el estado de tu credito</h1>
                    {/* DISENIO ANTERIOR [NO BORRAR] <h6>Crea tu cuenta y solicita tu cupo de crédito</h6> */}
                </Col>
            </Row>
            <Row gutter={24} className="mt-20">
                <Col xs={24}>
                    <h2 style={{ fontWeight: '600', textAlign: 'left' }}>Información personal</h2>
                </Col>
            </Row>
            <div className="formDatos">
                <Row gutter={24} className="mt-20">
                    <Col md={12} xs={24}>
                        <div className="formPersonal">
                            <label>Nombres</label>
                            <Input className="input-width" value={(props.formData.name) ? props.formData.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : ""} onChange={e => handleChange(e, 'name')} />
                        </div>
                    </Col>
                    <Col md={12} xs={24}>
                        <div className="formPersonal">
                            <label>Apellidos</label>
                            <Input className="input-width" value={(props.formData.lastName) ? props.formData.lastName.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : ""} onChange={e => handleChange(e, 'lastName')} />
                        </div>
                    </Col>
                </Row>
                <Row gutter={24} className="mt-20">
                    <Col md={24} xs={24}>
                        <div className="formPersonal">
                            <label>Fecha de Nacimiento</label>
                            <Row gutter={24}>
                                <Col md={8} xs={24} className='mb-20'>
                                    <Select style={{ width: '100%' }} placeholder="Día" value={(props.formData.birthday) ? props.formData.birthday : null} onChange={e => handleChange(e, 'birthday')} showSearch optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    // filterSort={(optionA, optionB) =>
                                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    // }
                                    >
                                        {[...Array(31)].map((item, index) => {
                                            return (
                                                <Option key={(index + 1).toString().padStart(2, '0')}>{"" + (index + 1)}</Option>
                                            )
                                        })}
                                    </Select>
                                </Col>
                                <Col md={8} xs={24} className='mb-20'>
                                    <Select style={{ width: '100%' }} placeholder="Mes" value={(props.formData.birthmonth) ? props.formData.birthmonth : null} onChange={e => handleChange(e, 'birthmonth')} showSearch optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    // filterSort={(optionA, optionB) =>
                                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    // }
                                    >
                                        {meses.map((item) => {
                                            return <Option key={item.value}>{item.label}</Option>
                                        })}
                                    </Select>
                                </Col>
                                <Col md={8} xs={24} className='mb-20'>
                                    <Select style={{ width: '100%' }} placeholder="Año" value={(typeof props.formData.birthyear !== 'undefined') ? props.formData.birthyear : null} onChange={e => handleChange(e, 'birthyear')} showSearch optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    // filterSort={(optionA, optionB) =>
                                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    // }
                                    >
                                        {[...Array(90)].map((item, index) => {
                                            let currentYear = new Date().getFullYear();
                                            return <Option key={currentYear - index}>{"" + (currentYear - index)}</Option>
                                        })}
                                    </Select>
                                </Col>
                            </Row>

                        </div>
                    </Col>

                </Row>
            </div>
        </div>
    );
}

export default Personal;