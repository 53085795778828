import React, { useEffect, useContext } from 'react';

// context 
import { AppContext } from '../../../context/AppContext';

// Styles 
import efectyLogo from '../../../assets/img/efecty-logo.png';
import './Conditions.css';

// components AntD
import { Row, Col, Select, Slider, Input, Modal, Collapse, Divider, Table, Button, Tooltip } from "antd";


// services 
import registerServices from "../../../services/login/RegisterServices";
import Soat from './soat/Soat';
import arrayPayDays from "../../../constants/arrayPayDays";
import { InfoCircleFilled } from "@ant-design/icons";


// Constantes 
const { Panel } = Collapse;
const { Option } = Select;

const columnsQuota = [
  { title: 'Dias Laborados', dataIndex: 'day', key: 'day' },
  { title: 'Cupo Disponible', dataIndex: 'amount', key: 'amount' }
];

const Conditions = (props) => {

  const [companies, setCompanies] = React.useState([]);
  const [documentTypes, setDocumentTypes] = React.useState([]);
  const [msg, setMsg] = React.useState({});
  const [baseActivos, setBaseActivos] = React.useState([]);
  const [cicloUser, setCicloUser] = React.useState(0);
  const [isSelectedFee, setIsSelectedFee] = React.useState(false);
  // const [datesList, setDatesList] = React.useState([]);
  const [bankList, setBankList] = React.useState([]);
  const [walletList, setWalletList] = React.useState([]);
  const [maxAmount, setMaxAmount] = React.useState(0);
  const [tableDataQuota, setTableDataQuota] = React.useState([]);
  // const [isSelectedButton, setIsSelectedButton] = React.useState(null);


  // context 
  const { setLoader } = useContext(AppContext);


  /* Tipos de Cuenta */
  const bankTypeAccountInfo = [
    { id: 1, name: 'Cuenta de ahorros', code: "CA" },
    { id: 2, name: "Cuenta corriente", code: "CC" },
    { id: 3, name: "Tarjeta Prepago Maestro", code: "TP" },
    { id: 3, name: "Depósitos Electrónicos", code: "DE" },
  ];

  useEffect(() => {
    getDocumentTypes();
    getCompanies();
    // getInitialData();
    getOutlayData();

    if (localStorage.basicInfo) {
      const { identification_id, name, last_name, email, document_type, company } = JSON.parse(localStorage.basicInfo);
      // let companySelected = "";
      // for (let i = 0; i < companies.length; i++) {
      //   const element = companies[i];
      //   if (company.toUpperCase().startsWith(element.socialReason.toUpperCase())) {
      //     companySelected = element.idCompany + "||" + element.socialReason;
      //     break;
      //   }
      // }
      props.setFormData({ ...props.formData, identificationId: identification_id.toString(), doty_id: document_type, name, lastName: last_name, email, companyName: company })
    }
    /*if(typeof props.formData.cyclesBefore !== 'undefined'){
      setCiclos(props.formData.cyclesBefore);
    }*/

    const found = arrayPayDays.find(arrayDays => arrayDays.days == props.result);

    if (found)
      setMaxAmount(found?.amount);
    if (props.result > 22)
      setMaxAmount(arrayPayDays[arrayPayDays.length - 1].amount);
    if (props.result < 5)
      setMaxAmount(0);




  }, []);



  /* Obtener datos iniciales */
  const getInitialData = () => {
    if (typeof localStorage.access_token !== "undefined") {
      /*registerServices.getInitialData().then(res => {
          if(res.status === 200){
              setPartialCapacity(res.data.partialCapacity);
              setSalaries(res.data.salaries);
          }
      });*/
    } else {

    }
  }

  /* Obtener datos de desembolso */
  const getOutlayData = () => {
    setLoader(true);
    registerServices.getOutlayData().then(res => {
      if (res.status === 200) {
        setBankList(res.data.bankInfo);
        setWalletList(res.data.walletInfo);
        setLoader(false);
      }
    }).catch(err => {
      console.log(err);
      setLoader(false);
    });
  }

  /* obtener lista de tipos de documentos */

  const getDocumentTypes = () => {
    setLoader(true);
    registerServices.getDocumentTypes().then(response => {
      setDocumentTypes(response.data)
      setLoader(false);
    }).catch(err => {
      Modal.error({ title: "Error", content: "No se pudo cargar el listado de tipos de documentos" });
      setLoader(false);
    });
  }

  /*  obtener lista de empresas */
  const getCompanies = () => {
    setLoader(true);
    registerServices.getCompanies().then(res => {
      setCompanies(res.data.companyRow);
      setLoader(false);
    }).catch(err => {

      Modal.error({ title: "Error", content: "No se pudo cargar el listado de empresas" });
      setLoader(false);
    })
  }

  /* formateo de numeros */

  const format = (d) => {
    var formatter = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumSignificantDigits: 1,
      maximumSignificantDigits: 10,
      minimumFractionDigits: 1,
      maximumFractionDigits: 4,

    });

    return formatter.format(d);
  };

  /* comprobacion de documento */
  const validationDocument = (e) => {
    // setLoader(true);
    let identificationId = e.target.value

    if (identificationId.length > 0) {
      registerServices.checkDocument(identificationId)
        .then(response => {

          if (response.data) {

            props.setFormData({ ...props.formData, identificationId: "" });
            Modal.error({ title: "Error", content: "Este número de documento ya fue registrado" });
            setLoader(false);
          } else {
            registerServices.getActivosInitialData(identificationId)
              .then(response => {

                if (response.data) {
                  setBaseActivos(response.data.baseActivos);
                  setLoader(false);
                }

              }).catch(err => {
                console.log(err)
                setLoader(false);
              });
          }

        }).catch(err => {
          console.log(err);
          setLoader(false);
        });
    }

  }


  /* Carga de Ciclos de pago */

  const loadCycles = (e) => {

    setLoader(true);
    let companyId = e.split("||")[0];
    let companyName = e.split("||")[1];

    setIsSelectedFee(false);

    if (parseInt(companyId) > 0) {
      registerServices.getCycles(companyId)
        .then(response => {
          // console.log("RESPONSE")
          // console.log(response)
          registerServices.getUxInitialData(companyId).then(res => {
            // console.log("RES")
            console.log(res)
            let cycles = [];
            let respCycles = response.data.cycles;
            // console.log(respCycles)
            let cicloUser = undefined;
            if (baseActivos === null) {

              props.setFormData({
                ...props.formData,
                companyId,
                companyName,
                companySelected: e,
                salary: null,
                split: 0,
                salarySelected: "",
                splitSelected: 0,
                monto: (res.data.partialCapacity < props.formData.monto) ? res.data.partialCapacity : props.formData.monto,
                cyclesBefore: respCycles,
                partialCapacity: res.data.partialCapacity,
                fee: res.data.split,
                numPayments: res.data.paymentSupport,
                numWorking: res.data.workingSupport,
                // cantCuotas: res.data.split
              });

            } else {
              let CiclosDeBase = [];
              let baac_fecha_pago = "";
              respCycles.forEach(function (el) {
                baseActivos.forEach(function (el2) {
                  if (parseInt(companyId) === el2.activo.baac_company_id) {
                    baac_fecha_pago = el2.activo.baac_fecha_pago;
                    if (el2.ciclo_nomina.length > 0) {
                      CiclosDeBase.push({ idCompanySalaries: el2.ciclo_nomina, companyRateName: el2.activo.baac_ciclo_nomina, companyPaymentDates: el2.activo.baac_fecha_pago });
                      if (el.idCompanySalaries === el2.ciclo_nomina) {
                        CiclosDeBase.pop();
                      }
                    }
                  }
                });
              });
              cycles = respCycles.concat(CiclosDeBase);

              cycles.forEach(function (el, i) {
                if ((el.companyPaymentDates).toLowerCase() === baac_fecha_pago.toLowerCase()) {
                  cicloUser = el.idCompanySalaries;
                }
              });


              props.setFormData({
                ...props.formData,
                companyId,
                companyName,
                companySelected: e,
                salary: null,
                split: 0,
                salarySelected: "",
                splitSelected: 0,
                monto: (res.data.partialCapacity < props.formData.monto) ? res.data.partialCapacity : props.formData.monto,
                cyclesBefore: cycles,
                partialCapacity: res.data.partialCapacity,
                fee: res.data.split,
                numPayments: res.data.paymentSupport,
                numWorking: res.data.workingSupport,
                // cantCuotas: res.data.split
              });
              setCicloUser(cicloUser);
            }


            if (cicloUser === undefined) {
              setCicloUser(null)
            } else {
              let cycleRes = [];
              cycles.forEach(function (elc, i) {
                if (elc.idCompanySalaries === cicloUser) {
                  cycleRes.push(elc);
                }
              });

              //dataToProps.cyclesBefore = cycleRes;
              props.setFormData({
                ...props.formData,
                companyId,
                companyName,
                companySelected: e,
                salary: null,
                split: 0,
                salarySelected: "",
                splitSelected: 0,
                monto: (res.data.partialCapacity < props.formData.monto) ? res.data.partialCapacity : props.formData.monto,
                cyclesBefore: cycleRes,
                partialCapacity: res.data.partialCapacity,
                fee: res.data.split,
                numPayments: res.data.paymentSupport,
                numWorking: res.data.workingSupport,
                // cantCuotas: res.data.split
              });

              setCicloUser(cicloUser);
            }

            setLoader(false);

          }).catch(err => {
            console.log(err);

            setLoader(false);
          })


        }).catch(err => {
          console.log(err);
          setLoader(false);
        });
    }
  }

  const setData = (linkList) => {
    let rows = [];
    if (linkList) {
      for (let i = 0; i < linkList.length; i++) {
        let item = linkList[i];
        let row = {
          key: i,
          ...item,
        }
        rows.push(row);
      }
    }
    return rows;
  }

  const handleChange = (value, name) => {

    if (typeof value.target === "undefined") {
      if (isNaN(value)) {
        console.log(typeof value.target.value)
        console.log("ASD")
        if (value.split("||").length > 1) {
          console.log("qwe")
          let secondValue = (value.split("||")[1]).split(":")[1];
          let secondValueName = (value.split("||")[1]).split(":")[0];
          props.setFormData({ ...props.formData, [name]: value.split("||")[0], [secondValueName]: secondValue });
        } else {
          console.log("aczxc")
          props.setFormData({ ...props.formData, [name]: (value) });
        }
      } else {
        console.log("nbmbvnmb")
        props.setFormData({ ...props.formData, [name]: (value) });
      }
    } else {
      if (name !== "account_number") {
        console.log("plkpjhkl")
        props.setFormData({ ...props.formData, [name]: (value.target.value).replace(/\D/g, "") });
      }
    }

    let arrayToCompare = ["identificationId", "account_number"];

    if (arrayToCompare.includes(name)) {
      if (props.formData[name + "2"] !== value.target.value && props.formData[name + "2"] !== "" && typeof props.formData[name + "2"] !== "undefined") {
        setMsg({ [name]: "Este campo no coincide con el anterior" });
        let varCompare = "compare" + name + "2";
        if (name === "account_number") {
          if (!isNaN(value.target.value)) {
            console.log("zcxzx2")
            props.setFormData({ ...props.formData, [varCompare]: false, [name]: (value.target.value).replace(/\D/g, "") });
          }
        } else {
          console.log("tyutyutyu")
          props.setFormData({ ...props.formData, [varCompare]: false, [name]: (value.target.value).replace(/\D/g, "") });
        }
      } else {
        setMsg({ [name]: "" });
        let varCompare = "compare" + name + "2"
        if (name === "account_number") {
          if (!isNaN(value.target.value)) {
            console.log("987987987")
            props.setFormData({ ...props.formData, [varCompare]: true, [name]: (value.target.value).replace(/\D/g, "") });
          }
        } else {
          console.log("12313213")
          props.setFormData({ ...props.formData, [varCompare]: true, [name]: (value.target.value).replace(/\D/g, "") });
        }
      }
    }

  }

  const handleChangeSalary = (value, name) => {
    let arrValues = value.split("||");
    let rateName = (arrValues[1]).split(":")[1];
    console.log(rateName)
    if (arrValues.length > 1) {
      props.setFormData({
        ...props.formData,
        salary: value.split("||")[0],
        rateName,
        cantCuotas: 1,
        // por si lo piden segun los pagos empresa
        // ((rateName === "30" || rateName === "Mensual") ? 1 : 2),
        salarySelected: value
      });
    } else {
      props.setFormData({
        ...props.formData,
        [name]: value,
        salarySelected: value
      });
    }
  }

  const handleCompare = (value, name) => {
    if (props.formData[name] !== (value.target.value).replace(/\D/g, "")) {
      setMsg({ [name]: "Este campo no coincide con el anterior" });
      let varCompare = "compare" + name
      if (name === "account_number") {
        if (!isNaN(value.target.value)) {
          props.setFormData({ ...props.formData, [varCompare]: false, [name + "2"]: (value.target.value).replace(/\D/g, "") });
        }
      } else {
        props.setFormData({ ...props.formData, [varCompare]: false, [name + "2"]: (value.target.value).replace(/\D/g, "") });
      }
    } else {
      setMsg({ [name]: "" });
      let varCompare = "compare" + name
      if (name === "account_number") {
        if (!isNaN(value.target.value)) {
          props.setFormData({ ...props.formData, [varCompare]: true, [name + "2"]: (value.target.value).replace(/\D/g, "") });
        }
      } else {
        props.setFormData({ ...props.formData, [varCompare]: true, [name + "2"]: (value.target.value).replace(/\D/g, "") });
      }
    }
  }

  // const selectCuotas = (i, value) => {
  //   props.setFormData({ ...props.formData, split: parseInt(value) });
  //   if (parseInt(value) === props.formData.split) {
  //     setIsSelectedFee(false)
  //     props.setFormData({ ...props.formData, split: 0 });
  //   } else {
  //     setIsSelectedFee(i);
  //   }
  // }

  useEffect(() => {
    getDatesList();
  }, [props.formData.companyId, props.formData.split, props.formData.salary, props.formData.monto, props.formData.product_id]);

  const getDatesList = () => {


    if (props.formData.companyId === null
      || typeof props.formData.companyId === "undefined"
      || props.formData.companyId === ""
      || props.formData.companyId === 0) {
      return false;
    }

    if (props.formData.split === null
      || typeof props.formData.split === "undefined"
      || props.formData.split === ""
      || props.formData.split === 0) {
      return false;
    }

    if (props.formData.salary === null
      || typeof props.formData.salary === "undefined"
      || props.formData.salary === ""
      || props.formData.salary === 0) {
      return false;
    }

    if (props.formData.monto === null
      || typeof props.formData.monto === "undefined"
      || props.formData.monto === ""
      || props.formData.monto === 0) {
      return false;
    }

    if (props.formData.product_id === null
      || typeof props.formData.product_id === "undefined"
      || props.formData.product_id === ""
      || props.formData.product_id === 0) {
      return false;
    }
    setLoader(true);

    let dataTosend = {
      companyId: props.formData.companyId,
      split: props.formData.split,
      companySalariesId: props.formData.salary,
      quantity: props.formData.monto,
      idProducto: props.formData.product_id,
      joined_company: props.formData.joined_company,
    }

    registerServices.getUxOutlayDatesList(dataTosend).then(res => {
      console.log(res)
      props.setFormData({ ...props.formData, datesList: res.data.datesList, interest: res.data.pagoTotal.intereses, administration: res.data.administrationValue, ivaValue: res.data.pagoTotal.ivaValue, totalValue: res.data.pagoTotal.totalValue });
      setLoader(false);
    }).catch(err => {
      Modal.error({
        title: "Error",
        content: err?.data?.message ?
          err.data.message :
          (err?.data ? err.data : "No se pudo obtener la información de pagos"),
      })
      console.log(err)
      setLoader(false);
    })
  }

  /* Seleccion en slider de monto */

  const handleSliderChange = (value) => {
    // console.log(value);
    setIsSelectedFee(false)
    props.setFormData({
      ...props.formData, monto: value,
      split: 0
    });
  }

  // const onChangeSwitch = (e) => {
  //   if (e) {
  //     props.setFormData({ ...props.formData, isBank: true, moyen: undefined, account_number: undefined, account_number2: undefined });
  //   } else {
  //     props.setFormData({ ...props.formData, isBank: false, moyen: undefined, account_type: undefined, account_number: undefined, account_number2: undefined });
  //   }
  // }

  const onChangeSelectsBanks = (e) => {
    if (e === 1) {
      props.setFormData({ ...props.formData, infoBank: true, isBank: true, moyen: undefined, account_number: undefined, account_number2: undefined });
    } else {
      props.setFormData({ ...props.formData, infoBank: true, isBank: false, moyen: 33, account_type: "", account_number: props.formData.identificationId, account_number2: undefined });
    }
  }

  const handleFee = (e) => {
    // console.log(e)
    setIsSelectedFee(parseInt(e))
    props.setFormData({ ...props.formData, split: parseInt(e) })
  }

  // console.log("fee: " + props.formData.fee)
  // console.log("isselected " + isSelectedFee)

  let tableQuota = setData([{ day: props.result, amount: maxAmount }])

  return (



    <div className="conditions">
      <Row gutter={24} className=" mb-20">
        <Col xs={24}>
          <h1>Simula tu crédito</h1>
          <h5 style={{ textAlign: 'center' }}>Selecciona tu empresa, elige el monto que deseas solicitar y calcula tus cuotas.
            Solo te puedes registrar si solo deseas solicitar un crédito</h5>
          <h6>Si quieres conocer más sobre Avanzo dale clic <a href="https://avanzo.co/quienes-somos">aquí</a></h6>
        </Col>
      </Row>
      <Row gutter={24} className="mb-20">
        <Col xs={24}>
          <div className="selectedProduct">
            {props.formData.product_name}
          </div>
        </Col>
      </Row>
      {
        props.formData.vehicle_flag &&
        <Row gutter={24} className="mb-20">
          <Col xs={24}>
            <Soat formData={props.formData} setFormData={props.setFormData} />
          </Col>
        </Row>
      }
      <Row gutter={24} className="">
        <Col md={12} xs={24}>
          <div className="formCondicion">
            <label>Tipo de Documento</label>
            <Select className="input-width" placeholder="Selecciona" onChange={e => handleChange(e, 'doty_id')} value={props.formData.doty_id} showSearch optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              } >
              {documentTypes.map((type, i) => (
                <Select.Option key={i} value={type.dotyId}>
                  {type.dotyDescription}
                </Select.Option>)
              )}
            </Select>
          </div>
        </Col>
        <Col md={12} xs={24}>
          <div className="formCondicion">
            <label>Número de Documento</label>
            <Input
              type="text"
              placeholder="Ingrese su número de documento de identidad"
              disabled={(!props.formData.doty_id || props.formData.identificationId)}
              className="input-width"
              onChange={e => handleChange(e, 'identificationId')}
              onBlur={validationDocument}
              value={(props.formData.identificationId) ? props.formData.identificationId : ""}
              maxLength={props.formData.doty_id === 1 ? 15 : 25}
              minLength={6}
            />
            {props.formData.identificationId && props.formData.identificationId.toString().length < 6 && "El numero de identificacion debe tener almenos 6 digitos"}
          </div>
        </Col>
      </Row>
      <Row gutter={24} className="mb-20">
        <Col md={12} xs={24}>
          <div className="formCondicion">
            <label>Confirmar Número de Documento</label>
            <Input
              type="text"
              placeholder="Confirme su número de documento de identidad"
              disabled={!props.formData.identificationId || (props.formData.identificationId && props.formData.identificationId.toString().length < 6)}
              className="input-width"
              onChange={e => handleCompare(e, 'identificationId')}
              value={(props.formData.identificationId2) ? props.formData.identificationId2 : ""}
              maxLength={props.formData.doty_id === 1 ? 15 : 25}
              minLength={6}
            />
            {msg['identificationId']}
          </div>
        </Col>
        <Col md={12} xs={24}>
          <div className="formCondicion">
            <label>Empresa</label>
            <Select
              className="input-width"
              placeholder="Selecciona"
              disabled={!props.formData.identificationId || !props.formData.identificationId2 || props.formData.identificationId !== props.formData.identificationId2}
              onChange={e => loadCycles(e)}
              value={(props.formData.companySelected) ? props.formData.companySelected : ""}
              showSearch optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
              defaultActiveFirstOption={true}>
              {(baseActivos && baseActivos.length > 0) ? companies.map((company, i) => (
                baseActivos.map((base, j) => (
                  company.idCompany === base.activo.baac_company_id ?
                    <Select.Option key={i} value={company.idCompany + "||" + company.socialReason} >
                      {company.socialReason}
                    </Select.Option> : ""))
              )) : ""}
              {(!baseActivos || baseActivos.length === 0) && companies.map(company => {
                return <Select.Option
                  key={company.idCompany}
                  disabled={company.socialReason.toUpperCase() !== props.formData.companyName.toUpperCase()}
                  value={company.idCompany + "||" + company.socialReason}
                  style={{
                    backgroundColor: company.socialReason.toUpperCase() !== props.formData.companyName.toUpperCase() ? "" : "#179ad7",
                    color: company.socialReason.toUpperCase() !== props.formData.companyName.toUpperCase() ? "" : "white",
                  }}
                >
                  {company.socialReason}
                </Select.Option>
              })}
            </Select>
          </div>
        </Col>
      </Row>

      {props.formData.companyId && props.formData.cyclesBefore.length > 0 &&
        <Row gutter={24} className="mb-20">
          <Col xs={24}>
            <div className="formCondicion">
              <label>Periodo de nómina</label>
              <Select className="input-width" placeholder="Selecciona" disabled={!props.formData.companyId} onChange={e => handleChangeSalary(e, "salary")} value={(props.formData.salarySelected && props.formData.cyclesBefore) ? props.formData.salarySelected : ""} showSearch optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }>
                {props.formData.cyclesBefore.map((type, i) => (
                  <Select.Option key={type.idCompanySalaries} value={type.idCompanySalaries + "||rateName:" + type.companyRateName + "||paymentDates:" + type.companyPaymentDates}>
                    {"Pago " + (type.companyRateName === "30" ? "Mensual" : (type.companyRateName === "15" ? "Quincenal" : type.companyRateName)) + " - " + type.companyPaymentDates}
                  </Select.Option>))
                }
              </Select>
            </div>
          </Col>
        </Row>
      }
      {props.formData.salary &&
        <Row gutter={24} className="mb-20">
          <Col xs={24}>
            <div className="boxCondicion">
              <div className="item-condicion">
                <div className="item-condicion-empresa">Periodo de pago {props.formData.companyName}</div>
                <div className="item-condicion-rateName">{(props.formData.rateName === "30" ? "Mensual" : (props.formData.rateName === "15" ? "Quincenal" : props.formData.rateName))}</div>
                <div className="item-condicion-paymentDate"> día(s) {props.formData.paymentDates}</div>
              </div>
            </div>
          </Col>
        </Row>
      }
      <br />
      {props.formData.salary && props.formData.product_id === 3 &&
        <Row gutter={24} className="mb-20">
          <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ background: 'whitesmoke', width: '50%' }}>
              <div className="infoQuota" >
                <span>Cupo disponible</span>
                <Tooltip placement="rightTop" title={"Este es el cupo de dinero máximo a prestar debido a los días que ha trabajado en la empresa"}>
                  <InfoCircleFilled />
                </Tooltip>
              </div>
              <Table
                dataSource={tableQuota}
                columns={columnsQuota}
                bordered
                locale={{ emptyText: 'No hay información sobre el cupo' }}
                style={{ width: 'fit-content', margin: 'auto', marginBottom: 10 }}
                pagination={false}
                size={'small'}
                tableLayout
              />
            </div>
          </Col>
        </Row>
      }
      <br />
      {props.formData.salary &&
        <div>
          <Row gutter={24} className="mb-20">
            <Col xs={24}>
              <h2 className="text-center">¿Cuánto deseas solicitar?</h2>
              <h6>Indíca el monto que desas solicitar y la cantidad de cuotas</h6>
            </Col>
          </Row>
          <Row gutter={24} className="mb-20">
            <Col xs={24}>
              <div className="monto">
                {format(props.formData.monto)}
              </div>
            </Col>
          </Row>
          <Row gutter={24} className="mb-20">
            <Col xs={24}>
              <div className="slider">
                <Slider
                  key={"slider-1"}
                  max={props.formData.product_id === 3 ? maxAmount : (props.formData.partialCapacity <= 50000 ? 50000 : props.formData.partialCapacity)}
                  min={50000}
                  step={10000}
                  className={"slider-amount"}
                  tipFormatter={function (d) { return format(d); }}
                  defaultValue={(props.formData.monto) ? props.formData.monto : 50000}
                  // disabled={props.formData.split > 0}
                  onChange={(e) => handleSliderChange(e)} style={{ width: "100%" }} />
              </div>
            </Col>
          </Row>
        </div>
      }
      {props.formData.monto && props.formData.rateName && props.formData.salary !== null &&
        <Row gutter={24} className="mb-20">
          <Col xs={24}>
            <div className="cuotas">
              <label>Cuotas</label>
              <Select
                className={"input-width-fee" + (isSelectedFee ? "-active" : "")}
                value={(props.formData.split) ? props.formData.split : null}
                onChange={(e) => handleFee(e)}
                style={{
                  textAlign: 'center',
                  // backgroundColor: props.formData.split ? "#179ad7" : "white", color: props.formData.split ? "white" : "black" 
                }}
              >
                {
                  props.formData.product_id === 3 ? [...Array(props.formData.cantCuotas)].map((element, i) => (
                    <Select.Option
                      key={(i + 1)}
                      value={i + 1}
                    >
                      {i + 1}
                    </Select.Option>
                  )) :
                    [...Array(props.formData.fee)].map((element, i) => (
                      <Select.Option
                        key={(i + 1)}
                        value={i + 1}
                      >
                        {i + 1}
                      </Select.Option>
                    ))
                }
              </Select>
              {/* <div className="formCuotas">

                {
                  [...Array(props.formData.cantCuotas)].map((element, i) => {
                    // console.log(element)
                    return <div
                      className={"cuota" + ((isSelectedFee === i || props.formData.split === (i + 1)) ? " active" : "")}
                      onClick={() => selectCuotas(i, i + 1)}
                      key={i}
                    >
                      {i + 1}
                    </div>
                  })
                }
              </div> */}
            </div>
          </Col>
        </Row>
      }
      {
        props.formData.split > 0 && <>
          <Row gutter={24} className="mb-20">
            <Col xs={24}>
              <div className="formCondicion">
                <Collapse ghost defaultActiveKey={'1'} >
                  <Panel header="Ver simulación" key="1" on>
                    <Row gutter={24} className="mb-20">
                      <Col xs={24}>
                        <h2 className='text-align-center-mobile'>Esto pagarías</h2>
                        <h6 className='text-align-center-mobile'>Conoce los cobros que realizamos</h6>
                      </Col>
                    </Row>
                    <Row gutter={24} className="mb-20">
                      <Col xs={24}>
                        <div className="cobros text-align-center-mobile">
                          <Row gutter={24} className="mb-20 ">
                            <Col md={12} xs={24}>
                              <b>Valor a desembolsar</b>
                            </Col>
                            <Col md={12} xs={24} className="text-right text-align-center-mobile">
                              {(isNaN(props.formData.monto)) ? 0 : format(props.formData.monto)}
                            </Col>
                          </Row>
                          {
                            props.formData.product_id === 1 &&
                            <>
                              <Row gutter={24} className="mb-20">
                                <Col md={12} xs={24}>
                                  <b>Tasa de interés</b>
                                </Col>
                                <Col md={12} xs={24} className="text-right text-align-center-mobile">
                                  25.5% EA - 1.911%
                                </Col>
                              </Row>
                              <Row gutter={24} className="mb-20">
                                <Col md={12} xs={24}>
                                  <b>Interés remunerado</b>
                                </Col>
                                <Col md={12} xs={24} className="text-right text-align-center-mobile">
                                  {isNaN(props.formData.interest) ? 0 : format(props.formData.interest)}
                                </Col>
                              </Row>
                            </>
                          }
                          <Row gutter={24} className="mb-20">
                            <Col md={12} xs={24}>
                              <b>Administración</b>
                            </Col>
                            <Col md={12} xs={24} className="text-right text-align-center-mobile">
                              {isNaN(props.formData.administration) ? 0 : format(props.formData.administration)}
                            </Col>
                          </Row>
                          <Row gutter={24} className="mb-20">
                            <Col md={12} xs={24}>
                              <b>IVA (19%)</b>
                            </Col>
                            <Col md={12} xs={24} className="text-right text-align-center-mobile">
                              {isNaN(props.formData.ivaValue) ? 0 : format(props.formData.ivaValue)}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Divider />
                    <Row gutter={24} className="mb-20">
                      <Col xs={24}>
                        <h2 className='text-align-center-mobile'>Plan de pagos</h2>
                        <h6 className='text-align-center-mobile'>Conoce las fechas y montos de pago</h6>
                      </Col>
                    </Row>
                    <Row gutter={24} className="mb-20">
                      <Col xs={24}>
                        <div className="cobros">
                          <Row gutter={24} className="mb-20 d-hidden-mobile">
                            <Col md={8} xs={24} className="text-center">

                            </Col>
                            <Col md={8} xs={24} className="text-center">
                              <b>Fecha</b>
                            </Col>
                            <Col md={8} xs={24} className="text-center">
                              <b>Monto</b>
                            </Col>
                          </Row>
                          {
                            props.formData.datesList && props.formData.datesList.map((element, i) => {
                              return <Row gutter={24} className="mb-20" key={i}>
                                <Col md={8} xs={24} className="text-center">
                                  <b>Cuota No. {i + 1}</b>
                                </Col>
                                <Col md={8} xs={24} className="text-center">
                                  {(element.date) ? element.date.split("T")[0] : ""}
                                </Col>
                                <Col md={8} xs={24} className="text-center">
                                  {isNaN(element.totalCuota) ? 0 : format(element.totalCuota)}
                                </Col>
                              </Row>
                            }
                            )
                          }
                          <Row gutter={24} className="mb-20">
                            <Col md={16} xs={24} className="text-right text-align-center-mobile">
                              <b>Total</b>
                            </Col>
                            <Col md={8} xs={24} className="text-center">
                              <b>{isNaN(props.formData.totalValue) ? 0 : format(props.formData.totalValue)}</b>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <p >
                          *Esta es una simulación de pagos tomando la fecha actual de referencia
                        </p>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </div>
            </Col>
          </Row>
          <Row gutter={24} className="mb-20">
            <Col xs={24}>
              <h2 className="text-center">¿Cómo deseas que te entreguemos tu dinero?</h2>
              <h6>Escoge alguna de estas opciones</h6>
            </Col>
          </Row>
          {/* <Row gutter={24} className="mb-20">
          <Col xs={24}>
            <div className="formCondicion">
              <div className="d-flex">
                Billetera Virtual {props.formData.isBank ? <Switch defaultChecked onChange={(e) => onChangeSwitch(e)} /> : <Switch onChange={(e) => onChangeSwitch(e)} />} Banco
              </div>

            </div>
          </Col>
        </Row> */}
          <Row gutter={24} className="bank-select mb-20">
            <Col xs={12} style={{ textAlign: 'center', justifyContent: 'center', width: '35%' }}>
              {/* DISENIO ANTERIOR <Button className="bank-button" onClick={(e) => onChangeSelectsBanks(1)} >Cuenta Bancaria</Button> */}
              <Button
                // style={{
                //   backgroundColor: isSelectedButton ? "#0086ff" : "",
                //   color: isSelectedButton ? "white" : "rgba(0, 0, 0, 0.85)",
                //   width: '100%'
                // }}
                style={{
                  // backgroundColor: isSelectedButton ? "#0086ff" : "",
                  // color: isSelectedButton ? "white" : "rgba(0, 0, 0, 0.85)",
                  backgroundColor: props.formData.isBank ? '#0086ff' : '',
                  color: props.formData.isBank ? 'white' : 'rgba(0, 0, 0, 0.85)',
                  width: '100%'
                }}
                onClick={(e) => onChangeSelectsBanks(1)}
              >
                Cuenta Bancaria
              </Button>
            </Col>
            <Col xs={12} style={{ textAlign: 'center', justifyContent: 'center', width: '35%' }}>
              {/* DISENIO ANTERIOR <Button className="bank-button-efecty" onClick={(e) => onChangeSelectsBanks(2)}> */}
              {/* <img className="logo-efecty" src={efectyLogo} alt="efecty-logo" /> */}
              <Button
                style={{
                  //   backgroundColor: isSelectedButton ? "" : (isSelectedButton === null ? "" : "#0086ff"),
                  //   color: isSelectedButton ? "rgba(0, 0, 0, 0.85)" : (isSelectedButton === null ? "" : "white"),
                  backgroundColor: (props.formData.isBank === undefined) ? '' : (props.formData.isBank === false ? "#0086ff" : ""),
                  color: (props.formData.isBank === undefined) ? 'rgba(0, 0, 0, 0.85)' : (props.formData.isBank === false ? "white" : "rgba(0, 0, 0, 0.85)"),
                  width: '100%',
                }}
                onClick={(e) => onChangeSelectsBanks(2)}
              >
                Efecty
              </Button>
            </Col>
          </Row>
          {props.formData.infoBank && (
            <Row gutter={24} style={{ alignItems: 'center', justifyContent: 'center' }} type="flex" className="mb-20">
              {
                (props.formData.moyen !== 33) ?
                  (<Col md={6} xs={24} className='mb-20'>
                    <label>Banco</label>
                    <Select className="input-width" placeholder="Selecciona" onChange={e => handleChange(e, 'moyen')} value={(props.formData.moyen) ? props.formData.moyen : ""} showSearch optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }>
                      {bankList && bankList.map((element, i) => {
                        return <Option key={i} value={element.idBank}>{element.bankName}</Option>
                      })}
                    </Select>
                    <br />
                  </Col>) :
                  (
                    <Col md={6} xs={24} className='mb-20'>
                      <label>Plataforma</label>
                      <Select className="input-width" placeholder="Selecciona" onChange={e => handleChange(e, 'moyen')} value={(props.formData.moyen) ? props.formData.moyen : ""} showSearch optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }>
                        {walletList && walletList.map((element, i) => {
                          if (element.idBank !== 32) {
                            return <Option key={i} value={element.idBank} disabled={props.formData.monto > 300000 && element.bankName === "EFECTY"}>{element.bankName} {props.formData.monto > 300000 && element.bankName === "EFECTY" ? <span>(Solo montos menores a $300.001)</span> : ""}</Option>
                          }
                        })}
                      </Select>
                      <br />
                    </Col>
                  )
              }

              {
                // Si es un banco
                props.formData.moyen !== 32 && props.formData.moyen !== 33 &&
                (<Col md={6} xs={24} className='mb-20'>
                  <label>Tipo de cuenta</label>
                  <Select className="input-width" placeholder="Selecciona" disabled={!props.formData.moyen} onChange={e => handleChange(e, 'account_type')} value={(props.formData.account_type) ? props.formData.account_type : ""} showSearch optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }>
                    {bankTypeAccountInfo && bankTypeAccountInfo.map((element, i) => {
                      return <Option key={i} value={element.id}>{element.name}</Option>
                    })}
                  </Select>
                  <br />
                </Col>)
              }

              {props.formData.moyen !== 32 &&
                (<><Col md={6} xs={24} className='mb-20'>
                  {props.formData.moyen === 33 ? <label>Número de cédula</label> : <label>Número de cuenta</label>}
                  <br />
                  <Input
                    type="text"
                    placeholder={props.formData.moyen === 33 ? "# de cédula" : "# de cuenta"}
                    disabled={props.formData.moyen === 33 || (props.formData.account_number === props.formData.identificationId) || !props.formData.moyen || !props.formData.account_type}
                    className="input-width"
                    onChange={e => handleChange(e, 'account_number')}
                    value={(props.formData.account_number) ? props.formData.account_number : ""}
                    maxLength={props.formData.moyen <= 30 ? 16 : (props.formData.doty_id === 1 ? 15 : 25)}
                  />
                  {/* <Input type="number" className="input-width" disabled={!props.formData.account_type} value={(props.formData.account_number) ? props.formData.account_number : ""} onChange={(e) => handleChange(e, 'account_number')} /> */}
                  <br />
                </Col>
                  <Col md={6} xs={24} className='mb-20'>
                    {props.formData.moyen === 33 ? <label>Confirmar # cédula</label> : <label>Confirmar # cuenta</label>}
                    <br />
                    <Input
                      type="text"
                      placeholder="Confirmar #"
                      disabled={!props.formData.account_number}
                      className="input-width"
                      onChange={e => handleCompare(e, 'account_number')}
                      value={(props.formData.account_number2) ? props.formData.account_number2 : ""}
                      maxLength={props.formData.moyen <= 30 ? 16 : (props.formData.doty_id === 1 ? 15 : 25)}
                    />
                    {msg["account_number"]}
                  </Col>
                </>)

                // (<><Col md={6} xs={24} lg={8} className='mb-20'>
                //   <label>Número de teléfono</label><br />
                //   <Input
                //     type="text"
                //     placeholder="# de teléfono"
                //     disabled={!props.formData.moyen}
                //     className="input-width"
                //     onChange={e => handleChange(e, 'account_number')}
                //     value={(props.formData.account_number) ? props.formData.account_number : ""}
                //     maxLength={10}
                //   />
                //   <br />
                //   {!isNaN(props.formData.account_number) && props.formData?.account_number.toString().length < 10 && props.formData?.account_number.toString().startsWith("3") && "El numero de telefono debe tener 10 digitos"}
                //   {!isNaN(props.formData.account_number) && !props.formData?.account_number.toString().startsWith("3") && "El numero de telefono no es valido"}
                // </Col>
                //   <Col md={6} xs={24} lg={8} className='mb-20'>
                //     <label>Confirmar teléfono</label><br />
                //     <Input
                //       type="text"
                //       placeholder="Confirmar #"
                //       disabled={
                //         !isNaN(props.formData.account_number) &&
                //         props.formData?.account_number.toString().length < 10 &&
                //         props.formData?.account_number.toString().startsWith("3") ||
                //         !isNaN(props.formData.account_number) &&
                //         !props.formData?.account_number.toString().startsWith("3") || !props.formData.account_number
                //       }
                //       className="input-width"
                //       onChange={e => handleCompare(e, 'account_number')}
                //       value={(props.formData.account_number2) ? props.formData.account_number2 : ""}
                //       maxLength={10}
                //     />
                //     {msg["account_number"]}
                //   </Col>
                // </>)
              }
            </Row>
          )}


          <div key={1} className={"info-account"}>
            {props.formData.moyen === 32 && <p>Después de la aprobación de tu crédito 2 días hábiles</p>}
          </div>
          <div key={2} className={"info-account"}>
            {
              props.formData.isBank && (
                <p>Después de la aprobación de tu crédito, tu desembolso se efectuará en 2 días hábiles. Si tienes cuenta Davivienda, se efectuará en 1 día hábil</p>
              )
            }
          </div>
          <div key={3} className={"info-account"}>
            {props.formData.moyen === 33 && <p>Después de la aprobación de tu crédito 1 día hábil</p>}
          </div>

          <br />

          <Row gutter={24} className="mb-20">
            <Col xs={24}>
              <h6>Recuerda que TU debes ser el titular de la cuenta. NO hacemos desembolsos a nombre de personas diferentes a ti.</h6>
            </Col>
          </Row>

        </>
      }

    </div >
  );
}

export default Conditions;