import axios from 'axios';
import BaseUrl from '../BaseUrl';
import { OS, currentBrowser } from '../Utils';


const dologin = (data)=>{

    let urlString = `${BaseUrl}Account/Token`;

    let body = {
       email: data.email,
       password: data.password
    }

    let headers = {
        'Access-Control-Allow-Origin': '*',
        'so': OS,
        'browser': currentBrowser
    }
    const config = {
        url :urlString,
        method : 'POST',
        headers: headers,
        data: body,
    };

    return axios(config)

}

const dorecovery = (data)=>{
    let urlString = `${BaseUrl}Account/ResetPassword`;

    let headers = {
        'Access-Control-Allow-Origin': '*',
        email: data.email,
    }
    const config = {
        url :urlString,
        method : 'GET',
        headers: headers,
        data: {},
    };

    return axios(config)

}  

export {dologin, dorecovery};