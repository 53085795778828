import {useEffect} from 'react';

const Logout = (props) => {

    useEffect(() => {
       localStorage.clear();
       window.location.reload();      
    }, []);
    
    return (
      <div className="logout"></div>
    );
  };
  
export default Logout;