const arrayPayDays = [
    {
        days: 5,
        amount: 50000,
    },
    {
        days: 6,
        amount: 50000,
    },
    {
        days: 7,
        amount: 50000,
    },
    {
        days: 8,
        amount: 50000,
    },
    {
        days: 9,
        amount: 60000,
    },
    {
        days: 10,
        amount: 60000,
    },
    {
        days: 11,
        amount: 70000,
    },
    {
        days: 12,
        amount: 80000,
    },
    {
        days: 13,
        amount: 90000,
    },
    {
        days: 14,
        amount: 90000,
    },
    {
        days: 15,
        amount: 100000,
    },
    {
        days: 16,
        amount: 100000,
    },
    {
        days: 17,
        amount: 110000,
    },
    {
        days: 18,
        amount: 120000,
    },
    {
        days: 19,
        amount: 130000,
    },
    {
        days: 20,
        amount: 130000,
    },
    {
        days: 21,
        amount: 140000,
    },
    {
        days: 22,
        amount: 150000,
    },
];

export default arrayPayDays