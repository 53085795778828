import axios from 'axios';
import BaseUrl from '../BaseUrl';


const getAllRatesSoat = (data)=>{

    let urlString = `${BaseUrl}SoatRates/GetAll`;
    const config = {
        url :urlString,
        method : 'GET',
    };
    return axios(config)

}


const generalServices = {getAllRatesSoat};

export default generalServices;