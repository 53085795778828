export const env = "prod";
let baseUrl = "";
switch (env) {
    case "dev":
        baseUrl = "http://localhost:4001/";
        break;
    case "test":
        baseUrl = "http://107.23.63.134:4001/";
        break;
    case "prod":
        baseUrl = "https://back.avanzo.co/";
        break;
    default:
        baseUrl = "http://localhost:4001/";
        break;
}


export default baseUrl;