import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

/* Framework */
import { Modal, Row, Col  } from 'antd';

/* CSS */
import './UploadDocuments.css';

/* Assets */
import imagen from "../../../assets/img/id-hold.jpg";


/* Services */ 
import customerService from "../../../services/customer/CustomerServices";


const UploadDocuments = (props) => {

    let history = useHistory();
    const [params, setParams] = useState(null);
    const [descripcion, setDescripcion] = useState(null);
    const [fileValidateBool, setFileValidateBool] = useState(false);
    const [sended, setSended] = useState(false);
    const [fileValidate, setFileValidate] = useState(false);
    const [archivosParams, setArchivosParams] = useState(null);
    

        
    const loadReasons = () =>{

        let rere_array = (props.objJWT.listRejectionReasons);     
        let params = [];
        let descripcion = [];
        let fileVal = false;
        rere_array.forEach(function(el){
        let _tratado = (el.rere_description).replace("Hola, ", "").replace(" Avanzo te presta TEL 4824824 ", "");
        if(descripcion.indexOf(_tratado) === -1 ){
            descripcion.push(_tratado);
        }              
            if(el.rere_param){
                if(el.rere_param === "fileValidate"){
                    fileVal = true;
                }else{
                    let _arrNombresYVariables = (el.rere_param).split("/");
                    if(typeof _arrNombresYVariables === "object" && typeof _arrNombresYVariables[0] !== "undefined" && typeof _arrNombresYVariables[1] !== "undefined"){
                        let _arrVariables = (_arrNombresYVariables[0]).split(",");
                        let _arrNombres = (_arrNombresYVariables[1]).split(",");

                        _arrVariables.forEach(function(el2,i){
                            if(el2 === "fileValidate"){
                                fileVal = true;
                            }else{
                                params.push({"label":_arrNombres[i], "name":el2});
                            }  
                        });
                    }else{
                        let _arrComa = (el.rere_param).split(",");
                        let _motivo = el.rere_motivo;
                        
                        _arrComa.forEach(function(el2){
                            if(el2 === "fileValidate"){
                                fileVal = true;
                            }else{
                                params.push({"label":_motivo, "name":el2});
                            }  
                        });
                    }                                 
                }

            }         
        });

        setParams(params);
        setDescripcion(descripcion);
        setFileValidateBool(fileVal);
    }

    useEffect(() => {       
        loadReasons();
    }, []);

    const onChangeFile = (e) =>{
        let name = e.target.getAttribute("name");
        let value = e.target.files[0];
        setArchivosParams({...archivosParams,[name]: value});
    }
    


    const updateNew =() =>{

        let data = {
          identificationId : props.objJWT.identificationId,
          company : props.objJWT.company,
          client_id : props.objJWT.clientid
        }
        
        
        if(!archivosParams){
            Modal.error({title: "Error", content: "Debe seleccionar al menos un archivo"});
            return false;
        }
        let archivos = [];
        
       
                for (const key in archivosParams) {
                    if (Object.hasOwnProperty.call(archivosParams, key)) {
                        const element = archivosParams[key];
                        archivos.push({ "name": key, "value": element });
                    }
                }
         
  
        data["archivos"] = archivos;
        customerService.updateNewRegister(data).then(response => {
          if(response.status === 200){
             Modal.success({title:"Acción realizada exitosamente", content:response.data.message});
            setSended(true);
          }else{
             Modal.error({title:'Error al cargar archivos.', content: response.data});     
          }
        }).catch(err => {
            console.log(err); 
        });
    }
    
    
    const onChangeFileValidate = (e) =>{ 

        let value = e.target.files[0]
        setFileValidate(value);
       
    }

    const validateNew = () =>{
        if(fileValidateBool){
          let data = {
            identificationId : props.objJWT.identificationId,
            company : props.objJWT.company,
            client_id : props.objJWT.clientid,
            fileValidate : fileValidate
          }
          
          customerService.addPhotoValidate(data).then(response => {
            if(response.status === 200){
               Modal.success({title:"Acción realizada exitosamente", content: response.data.message});
              setSended(true);
            }else{
               Modal.error({title:'Error al cargar archivo de validación.', content:response.data});     
            }
          }).catch(err => {
              console.log(err); 
          });
        }
    }
  

    return (<div>

        {!descripcion && <div>Cargando...</div>} 

        {descripcion && <div>
          {
            !sended && <div>
            <Row className="all-margin">
                <Col xxl={24} lg={24} md={24} sm={24} xs={24} ><br/><br/>
                <h3 className="text-center">No hemos podido procesar tu solicitud correctamente <br/>porque la solicitud que enviaste tiene algunos problemas,<br/>te contamos a continuación.</h3>  </Col>
            </Row>
            <Row gutter={24} className="all-margin">
                <Col xxl={24} lg={24} md={24} sm={24} xs={24} ><br/><br/>
                <h4 className="text-center">{descripcion.map((el, i)=>(
                    <li  key={i} className={"li-razon"}>{el.charAt(0).toUpperCase() + el.slice(1)}</li>
                ))}</h4>

                {
                    params.map((el, i) => (
                    <label key={i} className="labelUpload">{( typeof el.name.split("/")[1] !== "undefined") ? el.name.split("/")[1]: el.label.replace(" no válido","").replace(" no válida","")}:  <input
                    id={"file"+i}
                    name={( typeof el.name.split("/")[1] !== "undefined") ? el.name.split("/")[0]: el.name}
                    type="file"
                    onChange={(e) => onChangeFile(e)}
                  /></label>
                    ))
                }
                </Col>
            </Row>
            <br />
            {
                params.length > 0 && <Row className="all-margin">
                <Col xxl={24} lg={24} md={24} sm={24} xs={24} style={{textAlign:"center"}} ><br/>
                <button className="btn-blue" onClick={() => updateNew()}>Enviar archivos</button>
                </Col>
                <br/> 
            </Row>
            }
            
            {fileValidateBool &&   <Row className="all-margin">
                <Col xxl={4} lg={4} md={4} sm={0} xs={0} ></Col>
                <Col xxl={8} lg={18} md={8} sm={24} xs={24} style={{textAlign:"center"}}>
                    
                    <label className="labelUpload">Sube una foto tuya sosteniendo tu cédula de frente <br/>(Tal como aparece en la imagen):<br/>
                    <br/>
                    <input  type="file" onChange={(e) =>  onChangeFileValidate(e)}/></label>
                <br/><br/>
                    <button className="btn-blue" onClick={() => validateNew()}>Enviar fotos de Validación</button>
                </Col>
                <Col xxl={12} lg={12} md={12} sm={24} xs={24} >
                    <img alt="imagen validacion foto" src={imagen} className="img-responsive center-block" style={{width:"350px",maxWidth:"100%"}}/>
                </Col>
            </Row>}   
                </div>
            }
            {
                sended && <div>
                <Row className="all-margin">
                <Col xxl={24} lg={24} md={24} sm={24} xs={24} ><br/><br/>
                <h3 className="text-center">Hemos recibido los documentos que enviaste, los revisaremos y estaremos en contacto contigo.</h3> <br/><br/>
                <button className="btn-blue" onClick={() => {history.push("/")}}>Ir al Inicio</button> 
                 </Col>
            </Row>
                </div>
            }    
        
        </div>}
              
    </div>);
    
}

export default UploadDocuments;