import React, { useEffect, useState } from "react";
import {
    useHistory,
    useParams
  } from "react-router-dom";
import jwt from 'jwt-decode';  

/* Framework */
import { Row, Col  } from 'antd';

/* Components */
import UploadDocuments from './uploadDocuments/UploadDocuments';
import UploadRequestDocuments from './uploadRequestDocuments/UploadRequestDocuments';

/* CSS */
import './Deeplink.css';

/* Assets */
import logo from '../../assets/img/logo.png';


const Deeplink = (props) => {

    const history = useHistory();

    let { token } = useParams();
    const [objJWT, setObjJWT] = useState(null);
    // if(!token || typeof token === 'undefined'){

    // }
    useEffect(() => {       
        const initialValues = () =>{
            if(token && typeof token !== 'undefined'){
                setObjJWT(jwt(props.match.params.token)) 
            }else{
                history.push('/');
            }
        }  
        initialValues();
    }, []);

     
    return(
        <div className={"main"}>
            <Row gutter={24} className={"row-header"}>
                <Col md={24} xs={24} className={"text-center"}>
                    <img src={logo} alt="logo" className={"logo"}/>
                    Créditos al instante
                    <span className="bottom-line"></span>
                </Col>
            </Row>
           {
              objJWT &&
              <div>
                  {props.isRequest && <UploadRequestDocuments objJWT={objJWT} token={token}/>}
                  {!props.isRequest && <UploadDocuments objJWT={objJWT}/>}
              </div>
           }
        </div>
    );
    
}

export default Deeplink;