import React from "react";
import './Menu.css';
// import { Button } from 'antd';
import whiteLogo from '../../assets/img/white-logo.png';

const Menu = (props) => {
    console.log(props)
    return (
        <div className="header">
            <img src={whiteLogo} alt="banner-white-avanzo" />
            {/* <Button onClick={() => props.changeLogged(false)}>Cerrar sesion</Button> */}
        </div>
    );

}

export default Menu;