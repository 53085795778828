import React, { useContext, useEffect } from "react";

// components Antd
import { Button, Modal } from "antd";

// components 
import Password from "./password/Password";
import Files from "../files/Files";
import Conditions from "../request/conditions/Conditions";
import Product from "../request/product/Product";
import Contact from "./contact/Contact";
import Personal from "./personal/Personal";

// styles 
import './Register.css';

// assets 
import imgPrestamo from '../../assets/img/prestamo.png';
import imgExpress from '../../assets/img/express.png';
// import imgSoat from '../../assets/img/soat.png';
import registerServices from "../../services/login/RegisterServices";
// import bannerRegistrado from "../../assets/img/banner-registrado.png";
import { AppContext } from "../../context/AppContext";
import customerService from "./../../services/customer/CustomerServices";
import moment from 'moment';
import { useParams } from "react-router-dom";
import Sign from "./sign/Sign";

let today = new Date(Date.now());

const Register = () => {

    const [registeredObj, setRegisteredObj] = React.useState(null);
    const [currentStep, setCurrentStep] = React.useState(1);
    const [mobileTranslation, setMobileTranslation] = React.useState("mtl-10");
    const [titleMobileTranslation, setTitleMobileTranslation] = React.useState("mtr-5");
    const [formData, setFormData] = React.useState({
        // monto: 80000,
        cuotas: 0,
        partialCapacity: 350,
        cyclesBefore: [],
        departmentId: undefined,
        infoBank: false,
        birthday: '',
        birthmonth: '',
        birthyear: '',
        paymentReport: [],
        joinedCompany: null
    });
    const { identificationId } = useParams();

    console.log(identificationId)

    const [exitoModal, setExitoModal] = React.useState(false);
    const { setLoader } = useContext(AppContext);
    let regexPassword = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;

    // localStorage.clear();

    const convertDate = (fec) => {
        let newD = fec.split("-")
        let dateN = newD[2] + "/" + newD[1] + "/" + newD[0]
        let dateMomentObject = moment(dateN, "DD/MM/YYYY");
        return dateMomentObject.toDate();
    }

    let diff = 0;
    let result = 0;

    if (registeredObj?.preRecord?.joined_company) {
        let newDate = convertDate(registeredObj.preRecord.joined_company);
        var res = new Date();
        res.setDate(res.getDate() + 1);
        diff = (res - newDate);

        result = Math.floor(diff / (1000 * 60 * 60 * 24));
        localStorage.removeItem("isRegistered");
        localStorage.removeItem("isPreRecord");
        localStorage.setItem("diffDays", result);
    } else if (registeredObj?.isPreRecord === false) {
        localStorage.setItem("isPreRecord", registeredObj?.isPreRecord);
        localStorage.removeItem("isRegistered");
        localStorage.removeItem("diffDays");
    } else if (registeredObj?.isRegistered) {
        localStorage.setItem("isRegistered", registeredObj?.isRegistered);
        localStorage.removeItem("isPreRecord");
        localStorage.removeItem("diffDays");
    }

    console.log("Dias desde la fecha de ingreso: " + result)

    // console.log(currentStep)

    const stepUp = () => {
        if (currentStep + 1 <= totalSteps()) {
            setCurrentStep(currentStep + 1);
        }
    }

    const stepDown = () => {
        if (currentStep - 1 >= 1) {
            setCurrentStep(currentStep - 1);
        }
    }

    // Arreglo de pasos
    const selectProduct = (id_product, name_product, vehicle_flag) => {
        setFormData({ ...formData, monto: 50000, product_id: id_product, product_name: name_product, vehicle_flag: vehicle_flag });
    }


    const steps = [
        {
            step: 1,
            title: 'Escoge tu producto',
            component: <Product result={result} selectProduct={selectProduct} key={1} stepUp={stepUp} formData={formData} />,
            progress: '16.6%'
        }, {
            step: 2,
            title: 'Define el monto',
            component: <Conditions result={result} key={2} formData={formData} setFormData={setFormData} />,
            progress: '50%',
        }, {
            step: 3,
            title: 'Ingresa tus datos',
            component: <Personal key={3} formData={formData} setFormData={setFormData} />,
            substeps: [
                {
                    step: 4,
                    title: 'Ingresa tus datos',
                    component: <Contact key={4} formData={formData} setFormData={setFormData} />,
                    type: 'sub',
                    progress: '81.3%',
                }, {
                    step: 5,
                    title: 'Ingresa tus datos',
                    component: <Files key={5} formData={formData} setFormData={setFormData} />,
                    type: 'sub',
                    progress: '87.5%',
                }, {
                    step: 6,
                    title: 'Ingresa tus datos',
                    component: <Password key={6} formData={formData} setFormData={setFormData} />,
                    type: 'hito',
                    progress: '93.7%',
                }, {
                    step: 7,
                    title: 'Ingresa tus datos',
                    component: <Sign key={7} formData={formData} setFormData={setFormData} />,
                    type: 'hito',
                    progress: '100%',
                }
            ],
            progress: '75%',
        },
    ]


    // Funciones de cambio de pasos

    const calculateWidth = () => {
        const width = 100 / steps.length;
        return width;
    }

    const getProgress = () => {

        let progress = "0%";
        steps.forEach(step => {
            if (step.step === currentStep) {
                progress = step.progress;
            } else {
                if (step.substeps) {
                    step.substeps.forEach(substep => {
                        if (substep.step === currentStep) {
                            progress = substep.progress;
                        }
                    });
                }
            }
        });
        return progress;
    }

    let totalSteps = () => {
        let totalSubsteps = 0;
        steps.forEach(step => {
            if (typeof step.substeps !== 'undefined') {
                totalSubsteps += step.substeps.length;
            }
        })
        return (steps.length + totalSubsteps);
    }




    useEffect(() => {
        setCurrentStep(1);
    }, []);

    useEffect(() => {
        switch (currentStep) {
            case 1:
                setMobileTranslation('mtl-10');
                setTitleMobileTranslation('mtr-5');
                break;
            case 2:
                setMobileTranslation('mtl-110');
                setTitleMobileTranslation('mtr-5');
                break;
            case 3:
                setMobileTranslation('mtl-210');
                setTitleMobileTranslation('mtr-5');
                break;
        }
    }, [currentStep]);

    useEffect(() => {
        customerService.validatorWithIdentification(identificationId).then(res => {
            console.log(res)
            setRegisteredObj(res.data);
            if (res.data.preRecord) {
                localStorage.setItem("basicInfo", JSON.stringify(res.data.preRecord));
            }
        }).catch(err => {
            console.log(err)
            Modal.error({
                title: "Ocurrio un error",
                content: err?.data?.message ? err.data.message : err.data,
            })
        })
    }, [identificationId]);

    const checkStatus = () => {
        if (typeof formData.product_id !== 'undefined' && currentStep === 1) {
            return false;
        }
        if (typeof formData.monto !== 'undefined' &&
            formData.monto !== "" &&
            typeof formData.split !== 'undefined' &&
            formData.split !== "" &&
            typeof formData.identificationId !== 'undefined' &&
            formData.identificationId !== "" &&
            formData.identificationId === formData.identificationId2 &&
            typeof formData.doty_id !== 'undefined' &&
            formData.doty_id !== "" &&
            typeof formData.salary !== 'undefined' &&
            formData.salary !== "" &&
            formData.salary !== 0 &&
            // formData.monto >= 80000 &&
            formData.split !== 0 &&
            (formData.isBank && formData.account_type !== 'undefined') &&
            formData.moyen !== 'undefined' &&
            typeof formData.account_number !== 'undefined' &&
            formData.account_number !== "" &&
            formData.account_number === formData.account_number2
            && currentStep === 2 && formData.moyen !== 32) {
            return false;
        } else if (typeof formData.monto !== 'undefined' &&
            formData.monto !== "" &&
            typeof formData.split !== 'undefined' &&
            formData.split !== "" &&
            typeof formData.identificationId !== 'undefined' &&
            formData.identificationId !== "" &&
            formData.identificationId === formData.identificationId2 &&
            typeof formData.doty_id !== 'undefined' &&
            formData.doty_id !== "" &&
            typeof formData.salary !== 'undefined' &&
            formData.salary !== "" &&
            formData.salary !== 0 &&
            // formData.monto >= 80000 &&
            formData.split !== 0 &&
            (!formData.isBank && formData.account_type !== 'undefined') &&
            formData.moyen !== 'undefined' &&
            typeof formData.account_number !== 'undefined' &&
            formData.account_number !== "" &&
            formData.account_number === formData.account_number2
            && currentStep === 2 && formData.moyen === 33) {
            return false;
        }
        // else if (typeof formData.monto !== 'undefined' &&
        //     formData.monto !== "" &&
        //     typeof formData.split !== 'undefined' &&
        //     formData.split !== "" &&
        //     typeof formData.identificationId !== 'undefined' &&
        //     formData.identificationId !== "" &&
        //     formData.identificationId === formData.identificationId2 &&
        //     typeof formData.doty_id !== 'undefined' &&
        //     formData.doty_id !== "" &&
        //     typeof formData.salary !== 'undefined' &&
        //     formData.salary !== "" && formData.salary !== null &&
        //     formData.salary !== 0 &&
        //     // formData.monto >= 80000 &&
        //     formData.split !== 0 &&
        //     (formData.isBank && formData.account_type !== 'undefined') &&
        //     formData.moyen !== 'undefined' &&
        //     typeof formData.account_number !== 'undefined' &&
        //     formData.account_number !== "" &&
        //     formData.account_number === formData.account_number2
        //     && currentStep === 2 && formData.moyen === 32 && formData.account_number.toString().startsWith("3") && formData.account_number.toString().length === 10) {
        //     return false;
        // }

        if (typeof formData.name !== 'undefined' &&
            formData.name !== "" &&
            typeof formData.lastName !== 'undefined' &&
            formData.lastName !== "" &&
            typeof formData.birthDate !== 'undefined'
            && currentStep === 3) {
            return false;
        }

        if (typeof formData.email !== 'undefined' &&
            formData.email !== "" &&
            formData.email === formData.email2 &&
            typeof formData.phoneNumber !== 'undefined' &&
            formData.phoneNumber !== "" &&
            formData.phoneNumber === formData.phoneNumber2 &&
            typeof formData.clie_address !== 'undefined' &&
            formData.clie_address !== "" &&
            typeof formData.muni_id !== 'undefined' &&
            typeof formData.departmentId !== 'undefined'
            && currentStep === 4) {
            return false;
        }

        if ((typeof formData.documentFront !== 'undefined' &&
            typeof formData.documentBack !== 'undefined' &&
            typeof formData.workingReport !== 'undefined' &&
            formData.product_id === 3 && currentStep === 5) ||
            (typeof formData.documentFront !== 'undefined' &&
                typeof formData.documentBack !== 'undefined' &&
                typeof formData.paymentReport !== 'undefined' &&
                typeof formData.workingReport !== 'undefined' &&
                formData.paymentReport.length >= formData.numPayments &&
                formData.product_id !== 3
                && currentStep === 5)) {
            return false;
        }

        if (!(formData.password !== formData.password2 || formData.password === "" ||
            typeof formData.password === "undefined" || formData.password.match(regexPassword) === null) && currentStep === 6) {
            return false;
        }

        return true;
    }
    console.log("Paso num: " + currentStep)
    const signUp = () => {


        if (typeof formData.doty_id === 'undefined' || formData.doty_id === '') {
            Modal.error({
                title: 'Error',
                content: 'No has seleccionado un tipo de documento',
            });
            return;
        }

        if (typeof formData.identificationId === 'undefined' || formData.identificationId === '' || formData.identificationId === 'NaN') {
            Modal.error({
                title: 'Error',
                content: 'Escribe tu número de identificación',
            });
            return;
        }

        if (typeof formData.name === 'undefined' || formData.name === '') {
            Modal.error({
                title: 'Error',
                content: 'Escribe tu nombre',
            });
            return;
        }

        if (typeof formData.lastName === 'undefined' || formData.lastName === '') {
            Modal.error({
                title: 'Error',
                content: 'Escribe tu apellido',
            });
            return;
        }

        if (typeof formData.email === 'undefined' || formData.email === '') {
            Modal.error({
                title: 'Error',
                content: 'Escribe tu correo',
            });
            return;
        }

        if (typeof formData.phoneNumber === 'undefined' || formData.phoneNumber === '') {
            Modal.error({
                title: 'Error',
                content: 'Escribe tu celular',
            });
            return;
        }

        if (typeof formData.birthDate === 'undefined' || formData.birthDate === '') {
            Modal.error({
                title: 'Error',
                content: 'Falta tu fecha de nacimiento',
            });
            return;
        }


        if (typeof formData.clie_address === 'undefined' || formData.clie_address === '') {
            Modal.error({
                title: 'Error',
                content: 'Falta tu direccion',
            });
            return;
        }

        if (typeof formData.departmentId === 'undefined' || formData.departmentId === '') {
            Modal.error({
                title: 'Error',
                content: 'Selecciona un departamento',
            });
            return;
        }

        if (typeof formData.muni_id === 'undefined' || formData.muni_id === '') {
            Modal.error({
                title: 'Error',
                content: 'Selecciona un municipio',
            });
            return;
        }



        if (typeof formData.documentFront === 'undefined' || formData.documentFront === '') {
            Modal.error({
                title: 'Error',
                content: 'Falta el adjunto con la imagen frontal de tu documento de identidad',
            });
            return;
        }

        if (typeof formData.documentBack === 'undefined' || formData.documentBack === '') {
            Modal.error({
                title: 'Error',
                content: 'Falta el adjunto con la imagen trasera de tu documento de identidad',
            });
            return;
        }

        if ((typeof formData.paymentReport === 'undefined' || formData.paymentReport === '') && formData.product_id === 1) {
            Modal.error({
                title: 'Error',
                content: 'Falta el adjunto con la imagen de tu comprobante de pago',
            });
            return;
        }

        if (typeof formData.monto === 'undefined' || formData.monto === '') {
            Modal.error({
                title: 'Error',
                content: 'No has seleccionado un monto',
            });
            return;
        }

        if (typeof formData.split === 'undefined' || formData.split === '') {
            Modal.error({
                title: 'Error',
                content: 'No has seleccionado un número de cuotas',
            });
            return;
        }



        if (typeof formData.salary === 'undefined' || formData.salary === '') {
            Modal.error({
                title: 'Error',
                content: 'No has seleccionado un periodo de pago de salario',
            });
            return;
        }

        if (typeof formData.product_id === 'undefined' || formData.product_id === '') {
            Modal.error({
                title: 'Error',
                content: 'No has seleccionado un producto',
            });
            return;
        }

        if (typeof formData.idCode === 'undefined' || formData.idCode === '') {
            Modal.error({
                title: 'Error',
                content: 'No has realizado aún la firma digital',
            });
            return;
        }

        if (typeof formData.password === 'undefined' || formData.password === '') {
            console.log("unde o vacio")
            Modal.error({
                title: 'Error',
                content: 'No has ingresado tu contraseña',
            });
            return;
        }

        // if (formData.password.match(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/) === null) {
        //     console.log("entra por lo menos")
        //     Modal.error({
        //         title: 'Error',
        //         content: 'El formato de la contraseña no coincide con lo requerido',
        //     });
        //     return;
        // }

        if (typeof formData.terminos === 'undefined' || formData.terminos === false) {
            Modal.error({
                title: 'Error',
                content: 'No has aceptado los términos y condiciones',
            });
            return;
        }

        if (typeof formData.politicas === 'undefined' || formData.politicas === false) {
            Modal.error({
                title: 'Error',
                content: 'No has aceptado las políticas de tratamiento de datos',
            });
            return;
        }

        if (formData.identificationId.toString().length < 6) {
            Modal.error({
                title: 'Error',
                content: 'El número de identificación no debe ser menor a 6 digitos',
            });
            return;
        }

        setLoader(true);
        registerServices.sendRegister(formData).then(response => {
            if (response.status === 200) {
                setCurrentStep(currentStep + 1);
                setExitoModal(true);
                setLoader(false);
            }
        }).catch(error => {
            setLoader(false);
            console.log(error)
            Modal.error({
                title: 'Error',
                content: error.response.data.message,
            });
        }
        );


    }


    const signUpSuccessfully = () => {
        window.location.href = "https://avanzo.co/registro-exitoso/";
    }

    // console.log(getProgress())
    return (
        console.log(formData),
        <div className="registro">
            <div className="step-container-top">
                <div className={"step-counter " + mobileTranslation}>
                    <div className={"step-counter-container " + titleMobileTranslation}>
                        {steps.map((step, index) => {
                            return (
                                <div className="step-counter-item" style={{ width: calculateWidth() + "%" }} key={index}>
                                    <div
                                        className="step-counter-item-text"
                                        style={{
                                            backgroundColor: step.progress === getProgress() ? "#0086ff" : "",
                                            color: step.progress === getProgress() ? "white" : "rgba(0, 0, 0, 0.85)",
                                            fontSize: step.progress === getProgress() && "15px",
                                        }}
                                    >
                                        {step.title}
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="step-bar">
                        <div className="step-bar-progress" style={{ width: getProgress() }}></div>
                        <div className="point-container">
                            {
                                steps.map((step, index) => {
                                    let addClass = [];
                                    switch (step.step) {
                                        case 1:
                                            currentStep >= 2 ? addClass[index] = 'back-green' : addClass[index] = '';
                                            break;
                                        case 2:
                                            currentStep >= 3 ? addClass[index] = 'back-green' : addClass[index] = '';
                                            break;
                                        case 3:
                                            currentStep >= 6 ? addClass[index] = 'back-green' : addClass[index] = '';

                                    }

                                    return (
                                        <div className={"step-counter-item-point " + addClass[index]} key={index}></div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="step-container">
                {steps.map((step) => {
                    if (step.step === currentStep) {
                        return step.component;
                    } else {
                        if (step.substeps) {
                            return step.substeps.map(substep => {
                                if (substep.step === currentStep) {
                                    // console.log(substep)
                                    return substep.component;
                                } else {
                                    return null;
                                }
                            });
                        } else {
                            return null
                        }
                    }
                })
                }
            </div>
            <div className="botones">
                {(currentStep > 1) &&
                    <Button className="button-req" style={{ backgroundColor: '#FFFFFF' }} onClick={() => stepDown()}>Volver</Button>
                }
                {
                    // Disenio anterior [NO BORRAR]
                    // (currentStep < totalSteps()) && currentStep !== 1 &&
                    (currentStep < totalSteps()) &&
                    <Button
                        onClick={() => stepUp()}
                        // type={"primary"}
                        className="button-req"
                        disabled={checkStatus()}>
                        Continuar
                    </Button>
                }
                {(currentStep === totalSteps()) &&
                    <Button
                        // type={"primary"}
                        onClick={() => signUp()}
                        className="button-req"
                        disabled={
                            formData.password !== formData.password2 || formData.password === "" ||
                            typeof formData.password === "undefined" || formData.password.match(regexPassword) === null
                        }
                    >
                        SOLICITAR DINERO
                    </Button>
                }
            </div>

            {(exitoModal) && (signUpSuccessfully())}


        </div >
    );
};

export default Register;