import React from "react";
import {
    useParams
} from "react-router-dom";

// Assets 
import logo from "../../../assets/img/logo.png";

const Legal = () => {

    let { url } = useParams();
    console.log(url);


    if (url === "terminos") {
        return (
            <div className="legal">
                
                <div class="terms-conditions">
                    <img src={logo} style={{width:"200px", display:"block", margin:"auto"}} alt="logo"/>
    <h1 style={{textAlign: "center"}}><strong>TÉRMINOS Y CONDICIONES DE USO DE LA PÁGINA WEB DE AVANZO </strong></h1>
    <p><strong>&nbsp;</strong></p>
    <p>VICTORIA INVERSIONES S.A.S. (en adelante, AVANZO) a través de su portal web www.avanzo.co (en adelante "página
        web") brinda la plataforma tecnológica para efectuar solicitudes de cupos de crédito rotativo y desembolsos, los
        cuales son cancelados a través de la modalidad de libranza o descuento directo autorizado por el Usuario.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>AVANZO solicita al visitante y Usuario de la página web la lectura de estos términos y condiciones antes de
        iniciar su navegación o utilizar los servicios ofrecidos a través de este portal web. El acceso y navegación en
        la página web, así como el registro en la plataforma tecnológica de AVANZO, se encuentra precedido de un “click”
        en el botón acepto, lo cual constituye el conocimiento y la aceptación expresa del Usuario de estos términos y
        condiciones. En caso de no estar de acuerdo con estas condiciones sugerimos al visitante y Usuario que se
        abstenga de acceder o navegar por la página web.&nbsp;</p>
    <p><strong>&nbsp;</strong></p>
    <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.&nbsp;&nbsp;&nbsp; Definiciones&nbsp;</h2>
    <p><strong>&nbsp;</strong></p>
    <p>Los términos que a continuación se mencionan, se interpretan de acuerdo al siguiente alcance:&nbsp;</p>
    <p>&nbsp;</p>
    <ul>
        <li><strong>Cuenta de Usuario: </strong>Una vez el Usuario se registra en la página web, dispone de una cuenta
            a través de la cual accede a los servicios ofrecidos por AVANZO.&nbsp;</li>
    </ul>
    <p>&nbsp;</p>
    <ul>
        <li><strong>Cupo de Crédito Rotativo:</strong> Cupo de crédito que una vez aprobado, se mantiene disponible para
            ser desembolsado y transferido a favor del Usuario hasta completar el cupo total disponible, el cual se irá
            liberando a medida en que se realizan los pagos de los desembolsos solicitados.</li>
    </ul>
    <p>&nbsp;</p>
    <ul>
        <li><strong>Empleador o Entidad Pagadora: </strong>Es la persona jurídica, que tiene a su cargo la obligación
            del pago del salario o cualquiera que sea la denominación de la remuneración, en razón de la ejecución de un
            trabajo o labor.</li>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <ul>
        <li><strong>Libranza o Descuento directo:</strong> Es la autorización dada por el Usuario a la Entidad Pagadora,
            para que realice el descuento de su salario u honorarios disponibles, con el objeto de que las sumas
            descontadas sean giradas a favor de AVANZO como pago de los desembolsos realizados al Usuario.</li>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <ul>
        <li><strong>Usuario: </strong>Corresponde a la persona natural asalariada o contratada por prestación de
            servicios que solicita el cupo de crédito rotativo ofrecido por AVANZO a través de la página web, efectuando
            el pago de los desembolsos que se realicen a su favor a través del descuento sobre su salario u honorarios,
            previa autorización para el efecto al Empleador o Entidad Pagadora.</li>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.&nbsp;&nbsp;&nbsp; Uso de la página web, sus contenidos y sus
        servicios&nbsp;</h2>
    <p><strong>&nbsp;</strong></p>
    <p>Se encuentra prohibido el uso de la página web con fines ilícitos contra AVANZO o contra terceros que, de
        cualquier forma, puedan resultar perjudicados o la realización de cualquier actividad que impida el normal
        funcionamiento de la página web o la prestación de los servicios ofrecidos a través de ésta.&nbsp;</p>
    <p>&nbsp;</p>
    <p>Los contenidos incluidos en la página web son de propiedad exclusiva de AVANZO. Está prohibida su copia o
        reproducción total o parcial, su traducción, inclusión, transmisión, almacenamiento o acceso a través de medios
        digitales o de cualquier otro sistema sin autorización previa y escrita de AVANZO.&nbsp;</p>
    <p>&nbsp;</p>
    <p>Los enlaces (links) a otras páginas o portales de Internet, son ofrecidos como un servicio al Usuario. No
        obstante, se aclara que AVANZO no ha estado involucrada en la construcción de dichas páginas de internet y por
        ende no es responsable de su contenido o del uso o adquisición de los servicios ofrecidos en las mismas.&nbsp;
    </p>
    <p><strong>&nbsp;</strong></p>
    <p>Los elementos y cualquier información protegida por la propiedad intelectual, son de titularidad exclusiva de
        AVANZO, en consecuencia, se encuentra prohibida su reproducción o uso a cualquier título sin autorización previa
        y escrita de AVANZO.&nbsp;</p>
    <p>&nbsp;</p>
    <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.&nbsp;&nbsp;&nbsp; Información entregada a AVANZO</h2>
    <p>&nbsp;</p>
    <p>La información suministrada por el Usuario a través de la página web en cada uno de sus canales de contacto y/o
        de registro será utilizada exclusivamente para atender las solicitudes de los Usuarios y la prestación del
        servicio ofrecido por AVANZO. Particularmente, la información personal será tratada conforme a la Política de
        Tratamiento de Datos Personales publicada en el enlace
        https://avanzo.co/politicas-tratamiento-datos-personales/ , en la cual se establece la manera en que son
        tratados los datos personales compartidos por el Usuario y de terceros. Así mismo, en dicha política se informan
        los derechos a favor de los Titulares de información personal, y la forma de ejercerlos ante AVANZO.&nbsp;</p>
    <p>&nbsp;</p>
    <p>En todo caso, AVANZO de forma previa a cualquier actividad sobre datos personales, solicitará autorización al
        Titular, para que este conozca las finalidades particulares del tratamiento por parte de la entidad y sus
        derechos, lo anterior en cumplimiento de la Ley 1581 de 2012 y sus Decretos reglamentarios.</p>
    <p>&nbsp;</p>
    <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.&nbsp;&nbsp;&nbsp; Sobre los servicios ofrecidos a través de la página
        web&nbsp;</h2>
    <p><strong>&nbsp;</strong></p>
    <p>Cualquier persona podrá acceder a la página web a través de un dispositivo electrónico con conexión internet. Si
        un Usuario desea acceder a los servicios ofrecidos por AVANZO, deberá registrarse en la plataforma y crear una
        cuenta, autorizando previamente el tratamiento de la información personal.&nbsp;</p>
    <p>&nbsp;</p>
    <h2>3.1. Registro</h2>
    <p><strong>&nbsp;</strong></p>
    <p>Corresponde al paso inicial para efectuar la solicitud de cupo de crédito rotativo. Al efectuar el registro como
        Usuario, AVANZO solicitará el suministro de determinada información para la gestión de los servicios. Por ende,
        el Usuario se compromete a brindar información veraz y completa, así como a actualizarla cuando ello sea
        pertinente.&nbsp;</p>
    <p>&nbsp;</p>
    <p>Dentro de la información solicitada para el registro se encuentran los datos personales del Usuario, los cuales
        serán tratados conforme a la autorización otorgada por su parte y a la Política de Tratamiento de Datos
        Personales de AVANZO.&nbsp;</p>
    <p>&nbsp;</p>
    <p>Si el Usuario no efectúa el registro, podrá navegar por las diferentes secciones de la página web y explorar su
        contenido, sin poder acceder a los servicios ofrecidos. <strong>&nbsp;</strong></p>
    <p><strong>&nbsp;</strong></p>
    <h2>3.2. Usuario y contraseña&nbsp;</h2>
    <p>&nbsp;</p>
    <p>Cada una de las Cuentas de Usuario registradas en la página web tendrá tanto un usuario que constituye la
        identificación en el sistema, como una clave o contraseña de acceso a la cuenta, la cual es configurada por el
        Usuario durante su registro en la página web. Una vez efectuado el registro, el Usuario se compromete a adoptar
        las medidas de seguridad necesarias para impedir el acceso a su cuenta por parte de terceros no autorizados. En
        consecuencia, el Usuario será responsable por la confidencialidad de la información contenida en su cuenta, así
        como de las acciones que se puedan realizar a través de la misma por falta de cuidado o negligencia de su parte
        en el manejo de su usuario y/o contraseña.&nbsp;</p>
    <p>&nbsp;</p>
    <p>El Usuario deberá informar a AVANZO sobre cualquier actividad anormal o uso no autorizado de su cuenta y/o
        contraseña, correo electrónico, número de identificación u cualquier otra información que lo identifique ante
        AVANZO, de forma inmediata al conocimiento de los hechos, a través de los canales de contacto establecidos en el
        numeral 12 de estos términos y condiciones. Una vez notificado, AVANZO tomará las medidas conducentes para
        realizar a más tardar dentro de las 24 horas siguientes al aviso, el bloqueo de la Cuenta de Usuario sobre la
        cual se recibe la notificación&nbsp;</p>
    <p>&nbsp;</p>
    <h2>3.3. Ingreso a la Cuenta de Usuario</h2>
    <p><strong>&nbsp;</strong></p>
    <p>Efectuado el registro en la página web, el Usuario podrá iniciar sesión en el botón dispuesto para el efecto en
        la plataforma. Así mismo, a través de la Cuenta de Usuario éste podrá efectuar la solicitud de cupo de crédito
        rotativo y desembolsos sobre el mismo, así como conocer las condiciones particulares y cargos que apliquen a
        cada uno de los desembolsos. De igual forma, a través de su cuenta, el Usuario podrá conocer la información
        vinculada a su perfil, debiendo actualizarla o editarla cuando ello sea necesario para la correcta prestación de
        los servicios.&nbsp;</p>
    <p>&nbsp;</p>
    <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Olvido de contraseña</h2>
    <p><strong>&nbsp;</strong></p>
    <p>En el evento de no poder acceder a la cuenta por olvido de su contraseña, el Usuario podrá ir a la sección de
        “Iniciar Sesión” y seleccionar la opción de “Olvidé mi contraseña”, siguiendo el procedimiento establecido por
        AVANZO para la asignación y/o restablecimiento de la nueva contraseña.&nbsp;</p>
    <p><strong>&nbsp;</strong></p>
    <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 5.&nbsp;&nbsp;&nbsp; Pautas a tener en cuenta durante la navegación en la
        página web&nbsp;</h2>
    <p><strong>&nbsp;</strong></p>
    <p>Con el fin de garantizar el buen uso de la página web, el Usuario se compromete a cumplir las siguientes pautas
        que instruirán su conducta en la plataforma dispuesta por AVANZO:&nbsp;</p>
    <p>&nbsp;</p>
    <ul>
        <li>Observar los presentes Términos y Condiciones, la Política de Tratamiento de Datos Personales y cualquier
            otra política establecida por AVANZO que se encuentre publicada en la página web para su conocimiento.</li>
        <li>Usar el contenido de la página web o los servicios ofrecidos en la misma de forma diligente y para fines
            lícitos.</li>
        <li>Adoptar las medidas de seguridad necesarias para evitar el acceso no autorizado a la Cuenta de Usuario en la
            página web.</li>
        <li>No usar la página web como medio para desarrollar actividades ilegales o no autorizadas tanto en Colombia,
            como en cualquier otro país.</li>
        <li>Abstenerse de enviar correos electrónicos no deseados (spam) a otros Usuarios de la página web, como también
            transmitir virus o cualquier código de naturaleza destructiva por medio de la misma.</li>
        <li>No alterar, bloquear o realizar cualquier acto que impida el correcto funcionamiento de la página web, tales
            como y sin limitarse a: ataques informáticos, interceptación de comunicaciones, usurpación de identidad o
            falsedad en documentos.</li>
        <li>Presentar las peticiones, quejas o reclamos de forma respetuosa a través de los canales de contacto
            establecidos por AVANZO tanto en la Política de Tratamiento de Datos Personales como en la página web y los
            presentes términos y condiciones.</li>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 6.&nbsp;&nbsp;&nbsp; Responsabilidad del Usuario</h2>
    <p><strong>&nbsp;</strong></p>
    <p>El uso de la página web se realizará bajo responsabilidad del Usuario, en consecuencia, este será responsable
        por:&nbsp;</p>
    <p>&nbsp;</p>
    <ul>
        <li>Las operaciones realizadas a través de la página web sin requisito distinto a que estas sean efectuadas a
            través de las funcionalidades habilitadas en la página web y empleando la Cuenta de Usuario, la contraseña
            de acceso y cualquier otra seguridad adicional establecida por AVANZO.</li>
        <li>Los actos derivados del acceso no autorizado por terceros, como consecuencia de la falta de cuidado o
            negligencia del Usuario respecto a la custodia de su Usuario y contraseña de acceso.</li>
        <li>Disponer de, al menos, un dispositivo electrónico con conexión a internet para acceder a la página web y
            poder utilizar los servicios.</li>
    </ul>
    <p>&nbsp;</p>
    <p>El Usuario deberá mantener indemne a AVANZO por todo concepto o reclamación judicial o extrajudicial que se
        llegare a presentar con ocasión al incumplimiento por parte del Usuario de lo establecido en estos términos y
        condiciones.&nbsp;</p>
    <p><strong>&nbsp;</strong></p>
    <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 7.&nbsp;&nbsp;&nbsp; Derechos de AVANZO</h2>
    <p><strong>&nbsp;</strong></p>
    <p>En su calidad de propietario de la página web, AVANZO se reserva el derecho a ejercer las siguientes
        facultades:&nbsp;</p>
    <p>&nbsp;</p>
    <ul>
        <li>Modificar unilateralmente, en cualquier tiempo y por cualquier causa los presentes términos y condiciones,
            así como el diseño y funcionalidad de la página web. En tal caso, AVANZO informará de las modificaciones
            realizadas a los Usuarios, a través de correo electrónico y/o la página web. Efectuadas las modificaciones,
            cualquier uso por parte del Usuario representará su aceptación a las mismas.</li>
        <li>Denegar el registro en la página web a cualquier persona, en cualquier momento y por las razones que
            considere a su sano criterio.</li>
        <li>Cerrar Cuentas de Usuarios que, en concepto de AVANZO, puedan no estar haciendo un adecuado uso de la página
            web o los servicios ofrecidos a través de ella.</li>
        <li>Decidir sobre el contenido publicado en la página web y removerlo cuando así lo considere pertinente por ser
            ofensivo o ir en contravía de las normas legales o afectar derechos de terceros.</li>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 8.&nbsp;&nbsp;&nbsp; Responsabilidad de AVANZO</h2>
    <p><strong>&nbsp;</strong></p>
    <p>AVANZO realizará sus mejores esfuerzos para que la página web funcione correctamente las veinticuatro (24) horas
        del día y el Usuario pueda utilizar sus servicios sin interrupción, sin perjuicio de las limitaciones y
        restricciones establecidas e informadas previamente por AVANZO al Usuario, o de las limitaciones de los
        dispositivos o conexiones del USUARIO para acceder a la página web.&nbsp;</p>
    <p>&nbsp;</p>
    <p>AVANZO podrá interrumpir o suspender, sin que esto genere una responsabilidad de AVANZO frente al Usuario, los
        servicios de la página web por razones técnicas, de seguridad, por problemas que puedan presentarse por cortes
        en los servicios de conexión a internet, energía, por fuerza mayor o caso fortuito o hecho de un tercero no
        imputable a AVANZO.&nbsp;</p>
    <p>&nbsp;</p>
    <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 9.&nbsp;&nbsp;&nbsp; Exclusión de garantías y responsabilidades&nbsp;</h2>
    <p><strong>&nbsp;</strong></p>
    <p>AVANZO no otorga garantía ni se hace responsable, en ningún caso, de los daños y perjuicios de cualquier
        naturaleza que pudieren resultar de los siguientes hechos:</p>
    <p>&nbsp;</p>
    <ul>
        <li>La falta de disponibilidad, mantenimiento y continuidad del funcionamiento de la página web por causas no
            imputables a AVANZO. Cuando ello sea razonablemente posible, AVANZO advertirá previamente las interrupciones
            o limitaciones en el funcionamiento de la página web.</li>
        <li>La falta de utilidad, adecuación o validez de la página web y/o de sus servicios o contenidos para
            satisfacer expectativas del usuario.</li>
        <li>El uso negligente, ilícito o fraudulento o contrario a los presentes términos y condiciones por parte de uno
            o varios Usuarios.</li>
        <li>El incumplimiento por parte de terceros de sus obligaciones o compromisos en relación con los servicios
            prestados a través de la página web.</li>
    </ul>
    <p>&nbsp;</p>
    <h2>10. Seguridad en internet&nbsp;</h2>
    <p><strong>&nbsp;</strong></p>
    <p>AVANZO hará sus mejores esfuerzos para mantener la seguridad y confidencialidad de la página web, sin embargo
        AVANZO no controla ni garantiza, y por lo tanto no se hace responsable, por la presencia de códigos maliciosos
        ni de otros elementos en los contenidos de la página web y que hagan su aparición a pesar del cumplimiento de
        sus deberes y debida diligencia, de manera que puedan producir alteraciones en el sistema informático (software
        y hardware) del Usuario o en los documentos electrónicos almacenados en el sistema informático del
        Usuario.&nbsp;</p>
    <p>&nbsp;</p>
    <p>Se entiende por código malicioso el nombre que se le da a cualquier programa que ingresa a un dispositivo sin el
        conocimiento y la autorización inequívoca del responsable del mismo, dentro de los cuales se encuentran, los
        denominados <em>virus;</em><em> troyanos; gusanos; phishing; pharming; rootkits; backdoor (puertas traseras),
            keyloggers (capturadores de teclado), screen loggers, bootnets, sniffers (husmeadores de tráfico de la
            red)</em>, entre otros.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <h2>11. Uso de cookies</h2>
    <p><strong>&nbsp;</strong></p>
    <p>Las cookies son pequeños archivos que se almacenan en el dispositivo electrónico del Usuario para habilitar
        algunas funcionalidades de la plataforma tecnológica de AVANZO. Cualquier navegador que visite la página web
        podrá recibir cookies de AVANZO o de terceros, como entidades aliadas o proveedores de servicios.&nbsp;</p>
    <p>&nbsp;</p>
    <p>AVANZO podrá utilizar <em>cookies</em><em> persistentes</em> para reconocer al Usuario ya existente y facilitar
        el uso de los servicios sin necesidad de volver a iniciar sesión en la Cuenta de Usuario. Así mismo, AVANZO
        podrá utilizar <em>cookies de sesión</em> para mantener el seguimiento de los movimientos del Usuario en la
        página web, con el fin de no solicitar la información que el Usuario ha brindado previamente.&nbsp;</p>
    <p>&nbsp;</p>
    <p>La funcionalidad de cookies deberá ser autorizada por el Usuario, mediante un “click” en el botón acepto
        dispuesto en la página web, lo cual constituye el conocimiento y la aceptación expresa del Usuario respecto al
        uso de cookies.&nbsp;</p>
    <p>&nbsp;</p>
    <h2>12. Ayuda al Usuario</h2>
    <p>&nbsp;</p>
    <p>El Usuario podrá solicitar asistencia o elevar peticiones, quejas o reclamos a través de los siguientes canales
        de contacto:&nbsp;</p>
    <p>&nbsp;</p>
    <ul>
        <li>Envío de correo electrónico a <a href="mailto:operaciones@avanzo.co">operaciones@avanzo.co</a>.</li>
        <li>Directamente en las oficinas ubicadas en la dirección Cra. 18 #No. 86a - 14, Bogotá, Cundinamarca
            2.6&nbsp;km, las cuales serán recibidas en el siguiente horario: de lunes a viernes de 8am-6pm.</li>
        <li>A través de la línea telefónica (1) 4824824</li>
    </ul>
    <p>&nbsp;</p>
    <h2>13. Ley aplicable y jurisdicción</h2>
    <p><strong>&nbsp;</strong></p>
    <p>Estos términos y condiciones han sido dispuestos de conformidad con las leyes colombianas. Si cualquier
        disposición de estos términos y condiciones pierde validez, por cualquier razón, todas las demás disposiciones
        conservarán su fuerza obligatoria, carácter vinculante y mantendrán todos sus efectos.&nbsp;</p>
    <p>&nbsp;</p>
    <p>Cualquier controversia, acción o reclamación, que surja de la interpretación o aplicación de los presentes
        términos y condiciones, se someterá a decisión de los jueces de la República de Colombia.&nbsp;</p>
    <p>&nbsp;</p>
    <hr/>
    <p>&nbsp;</p>
</div>
            </div>        );
    } else {
        return (
            <div className="legal">
                
                <div class="terms-conditions">
                    <img src={logo} style={{width:"200px", display:"block", margin:"auto"}} alt="logo"/>
                    <h1 style={{textAlign: "center"}}>AUTORIZACIÓN TRATAMIENTO DE DATOS - REGISTRO CUENTA DE USUARIO EN PÁGINA WEB</h1>
                    <p>&nbsp;</p>
                    <p>Con la aceptación de este checkbox, autorizo expresamente a VICTORIA INVERSIONES S.A.S. identificada con NIT
                        901116431-9 (AVANZO), como Responsable del tratamiento de la información para utilizar mi nombre, teléfono de
                        contacto, correo electrónico, información financiera y demás datos personales compartidos con la compañía por
                        medio de este formulario con el fin de: a) Realizar estudios pre-scoring y consultas en Centrales de Riesgos con
                        el fin de validar la posibilidad de otorgar un cupo de crédito rotativo y desembolsos sobre el mismo; b)
                        Realizar gestiones de conocimiento a través de diferentes medios como páginas públicas de listados de personas
                        involucradas en actividades ilegales, lavado de activos o financiación del terrorismo así como contactando a las
                        posibles referencias aportadas; c) Verificación o actualización de información a través de correo físico o
                        electrónico, mensajes de texto MMS/SMS y/o telefónicamente, o cualquier otro medio de comunicación o transmisión
                        de datos accesible a la entidad; d) Almacenar mi información en sus bases de datos con el fin de gestionar la
                        relación contractual conmigo como titular de la información, incluyendo actividades de desembolsos de los
                        créditos solicitados, actividades de gestión de recuperación de cartera, confirmación y actualización de datos
                        ya sea directamente o con terceros; e) Enviarme correspondencia, correos electrónicos, mensajes de texto MMS/SMS
                        y cualquier otro tipo de comunicaciones en desarrollo de actividades publicitarias, de mercadeo, ofrecimiento de
                        productos o servicios nuevos y actuales que puedan ser considerados de mi interés; f) Compartir esta información
                        con terceros conforme se haga necesario para realizar actividades comprendidas dentro del objeto social de la
                        compañía y/o para el ejercicio de actividades relacionadas con estas mismas finalidades; g) Realizar encuestas
                        de satisfacción y percepción sobre los productos y servicios de la compañía por cualquier medio; h) Atender mis
                        peticiones, quejas o reclamos, o requerimientos realizados por autoridades en ejercicio de sus funciones; i)
                        Conservar la información para fines históricos y/o estadísticos. <br/> <br/>Declaro que como Titular de la
                        información puedo informarme sobre el tratamiento que se le da a mi información personal por medio de la
                        política de tratamiento de datos personales publicada en el siguiente
                        https://avanzo.co/politicas-tratamiento-datos-personales/ . <br/> <br/>Conozco que como Titular de la información
                        tengo derecho a solicitar, conocer, actualizar, rectificar y/o suprimir mi información personal de las bases de
                        datos de la entidad, esto último, siempre y cuando no tenga una relación contractual u obligación legal vigente
                        con la compañía. Igualmente, podré solicitar prueba y/o revocar el consentimiento otorgado para el tratamiento
                        de mis datos personales, conocer el uso que se le da a mi información y acceder gratuitamente a los datos objeto
                        de tratamiento al menos una vez al mes por medio de comunicación escrita a <a
                            href="mailto:operaciones@avanzo.co">operaciones@avanzo.co</a>
                            
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                </div>
            </div>
        );
    }
}

export default Legal;