import React, { useContext } from "react";

//context
import { AppContext } from "../../context/AppContext";

// assets 
import gifload from "../../assets/img/loading.gif";

// Styles 
import "./Loader.css";

const Loader = (props) => {
    const { loader } = useContext(AppContext);
    return loader ? (
        <div className="global-spinner-overlay">
            <p><img src={gifload} alt="loading" /></p>
        </div>       
    ): null;
}

export default Loader;