import axios from 'axios';
import BaseUrl from '../BaseUrl';



const getCompanies = (data) => {

    let urlString = `${BaseUrl}Company/GetAllForUsers`;
    const config = {
        url: urlString,
        method: 'GET',
    };
    return axios(config)

}

const getDepartments = () => {

    let urlString = `${BaseUrl}Departments/`;
    const config = {
        url: urlString,
        method: 'GET',
    };
    return axios(config)

}

const getStates = (departamento_id) => {

    let urlString = `${BaseUrl}municipalitiesByDepaId/${departamento_id}`;
    const config = {
        url: urlString,
        method: 'GET',
    };
    return axios(config)

}

const getDocumentTypes = () => {

    let urlString = `${BaseUrl}DocumentsType`;
    const config = {
        url: urlString,
        method: 'GET',
    };
    return axios(config)

}

const checkDocument = (identificationId) => {

    let urlString = `${BaseUrl}validations/validate_document_number/${identificationId}`;
    const config = {
        url: urlString,
        method: 'GET',
    };
    return axios(config)

}

const getActivosInitialData = (identificationId) => {

    let urlString = `${BaseUrl}BaseActivos/GetInitialInformation/${identificationId}`;
    const config = {
        url: urlString,
        method: 'GET',
    };
    return axios(config)

}

const getCycles = (companyId) => {
    let urlString = `${BaseUrl}Company/GetCyclesByCompanyId/${companyId}`;
    const config = {
        url: urlString,
        method: 'GET',
    };
    return axios(config)
}

const checkEmail = (email) => {
    let urlString = `${BaseUrl}validations/validate_email/${email}`;
    const config = {
        url: urlString,
        method: 'GET',
    };
    return axios(config)
}

const checkPhone = (telefono) => {
    let urlString = `${BaseUrl}validations/validate_phone_number/${telefono}`;
    const config = {
        url: urlString,
        method: 'GET',
    };
    return axios(config)
}

const getUxInitialData = (companyId) => {
    let urlString = `${BaseUrl}Account/getUxInitialData/${companyId}`;
    const config = {
        url: urlString,
        method: 'GET',
    };
    return axios(config)
}

const getOutlayData = () => {
    let urlString = `${BaseUrl}Request/GetOutlayData`;
    const config = {
        url: urlString,
        method: 'GET',
    };
    return axios(config)
}

const getUxOutlayDatesList = (data) => {
    // console.log(data)
    let urlString = `${BaseUrl}Register/getUXOutlayDatesList/`;
    const config = {
        url: urlString,
        method: 'GET',
        headers: {
            companyid: data.companyId,
            companysalariesid: data.companySalariesId,
            split: data.split,
            quantity: data.quantity,
            idproducto: data.idProducto,
            joined_company: data.joined_company,
        }
    };
    return axios(config)
}

const sendCodes = (data, isCall) => {

    let urlString = `${BaseUrl}Request/GenerateFirstCodes/`;
    const config = {
        url: urlString,
        method: 'POST',
        data: {
            userDocumentNumber: data.identificationId,
            phoneNumber: data.phoneNumber,
            email: data.email,
            name: data.name,
            lastName: data.lastName,
            iscall: isCall ? isCall : false
        }
    };
    return axios(config)
}


const checkCodes = (data) => {

    console.log(data);
    let urlString = `${BaseUrl}Request/ValidateFirstCodes/`;
    const config = {
        url: urlString,
        method: 'POST',
        data: {
            userDocumentNumber: data.identificationId,
            phonecode: data.codeCelular,
            emailcode: data.codeCorreo,
            code_hash: data.code_hash
        }
    };

    return axios(config)
}

const sendRegister = (data) => {

    var bodyFormData = new FormData();

    /* Datos de registro */

    bodyFormData.append('name', data.name);
    bodyFormData.append('lastName', data.lastName);
    bodyFormData.append('email', data.email);
    bodyFormData.append('identificationId', data.identificationId);
    bodyFormData.append('documentType', data.doty_id);
    bodyFormData.append('doty_id', data.doty_id);
    bodyFormData.append('city', data.muni_id);
    bodyFormData.append('muni_id', data.muni_id);
    bodyFormData.append('birthDate', data.birthDate);
    bodyFormData.append('salary', data.salary);
    bodyFormData.append('phoneNumber', data.phoneNumber);
    bodyFormData.append('company', data.companyId);
    bodyFormData.append('password', data.password);
    bodyFormData.append('documentFront', data.documentFront);
    bodyFormData.append('documentBack', data.documentBack);

    if (data.product_id === 1) {
        for (let i = 0; i < data.paymentReport.length; i++) {
            bodyFormData.append("paymentSupport", data.paymentReport[i]);
        }
    }

    bodyFormData.append('workingSupport', data?.workingReport);

    bodyFormData.append('gender', '');
    bodyFormData.append('vehicle', data.vehicle);
    if (!data.vehicle) {
        bodyFormData.append('vehicle_type', null);
        bodyFormData.append('license_plate_vehicle', null);
    } else {
        bodyFormData.append('vehicle_type', data.vehicle_type);
        bodyFormData.append('license_plate_vehicle', data.license_plate_vehicle);
    }

    bodyFormData.append('referenced', '');


    bodyFormData.append('clie_address', data.clie_address + ' ' + data.clie_address2);
    bodyFormData.append('clie_from', 'otro');
    bodyFormData.append('idCode', data.idCode);
    bodyFormData.append('isIntermediary', false);

    /* Datos de solicitud */

    bodyFormData.append('quantity', data.monto);
    bodyFormData.append('split', data.split);
    bodyFormData.append('moyen', data.moyen);
    bodyFormData.append('accountType', data.account_type);
    bodyFormData.append('accountNumber', data.account_number);
    bodyFormData.append('isBank', data.isBank);

    bodyFormData.append('interest', data.interest);
    bodyFormData.append('administration', data.administration);
    bodyFormData.append('iva', data.ivaValue);
    bodyFormData.append('otherValues', 0);
    bodyFormData.append('totalValue', data.totalValue);

    bodyFormData.append('idCompany', data.companyId);
    bodyFormData.append('loanData', data.loanData);
    bodyFormData.append('fileString', null);
    bodyFormData.append('file', "FILE");

    /*
        if (data.documentFront) {
            if (data.documentFront !== undefined) {
            bodyFormData.append('documentFront', data.documentFront);
            }
        }
    
        if (data.documentBack) {
            if (data.documentBack !== undefined) {
            bodyFormData.append('documentBack', data.documentBack);
            }
        }
    */
    if (data.propertyCardFront) {
        if (data.propertyCardFront !== undefined) {
            bodyFormData.append('propertyCardFront', data.propertyCardFront);
        }
    }

    if (data.propertyCardBack) {
        if (data.propertyCardBack !== undefined) {
            bodyFormData.append('propertyCardBack', data.propertyCardBack);
        }
    }


    //bodyFormData.append('workingSupport', data.workingSupport);

    //bodyFormData.append('salary_base', (data.salary_base).replace(/\./g, ""));
    //bodyFormData.append('biweekly_salary', (data.biweekly_salary).replace(/\./g, ""));
    //bodyFormData.append('general_deduction', (data.general_deduction).replace(/\./g, ""));
    bodyFormData.append('fromapp', false);
    bodyFormData.append('userEmailCode', data.codeCorreo);
    bodyFormData.append('userPhoneCode', data.codeCelular);
    bodyFormData.append('code_hash', data.code_hash);

    bodyFormData.append('request_overdraft', false);
    bodyFormData.append('ovre_id', null);
    bodyFormData.append('ovre_description', '');

    bodyFormData.append('id_producto', data.product_id);
    bodyFormData.append('especificaciones', data.especificaciones);

    bodyFormData.append('joined_company', data.joinedCompany);

    let urlString = `${BaseUrl}NewBothRegister/`;

    const config = {
        url: urlString,
        method: 'POST',
        data: bodyFormData
    }
    return axios(config)
}



const registerServices = { getCompanies, getDocumentTypes, checkDocument, getActivosInitialData, getOutlayData, getCycles, checkEmail, checkPhone, getDepartments, getStates, getUxInitialData, getUxOutlayDatesList, sendCodes, checkCodes, sendRegister };

export default registerServices;