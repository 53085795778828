import React, { useEffect } from "react";

//style
import './Password.css';

// components Antd
import { Row, Col, Input } from 'antd';



const Password = (props) => {

    const [countdown, setCountdown] = React.useState(60);

    // const { setLoader } = useContext(AppContext);


    useEffect(() => {
        props.setFormData({ ...props.formData, sended: false });
    }, []);

    const handleChange = (value, name) => {
        if (typeof value.target === "undefined") {
            if (isNaN(value)) {
                if (value.split("||").length > 1) {
                    let secondValue = (value.split("||")[1]).split(":")[1];
                    let secondValueName = (value.split("||")[1]).split(":")[0];
                    props.setFormData({ ...props.formData, [name]: value.split("||")[0], [secondValueName]: secondValue });
                } else {
                    props.setFormData({ ...props.formData, [name]: value });
                }
            } else {
                props.setFormData({ ...props.formData, [name]: value });
            }
        } else {
            props.setFormData({ ...props.formData, [name]: value.target.value });
        }

        if (name === "password") {
            validatePassword(value)
        }
    }


    const startCountdown = () => {
        if (countdown > 0) {
            setTimeout(() => {
                setCountdown((countdown > 0 && countdown < 60) ? countdown - 1 : 0);
            }, 1000);
        }
    }

    useEffect(() => {
        if (countdown > 0 && countdown < 60) {
            startCountdown();
        }
    }, [countdown]);


    const [msg, setMsg] = React.useState('');
    const handleCompare = (value, name) => {
        if (props.formData[name] !== value.target.value) {
            setMsg({ ...msg, [name]: "Este campo no coincide con el anterior" });
            let varCompare = "compare" + name
            props.setFormData({ ...props.formData, [varCompare]: false, [name + "2"]: value.target.value });
        } else {
            setMsg({ ...msg, [name]: "" });
            let varCompare = "compare" + name
            props.setFormData({ ...props.formData, [varCompare]: true, [name + "2"]: value.target.value });
        }
    }

    const validatePassword = (value) => {
        console.log(value.target.value);
        if ((value.target.value).match(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/)) {
            setMsg({ ...msg, password_error: "Contraseña segura" });
            console.log("Contraseña segura");
        } else {
            let _msg = "La contraseña debe contener al menos una letra mayúscula, una letra minúscula, un número, un caracter especial y una longitud mínima de 8 caracteres";
            setMsg({ ...msg, password_error: _msg });
            console.log("Contraseña insegura");
        }
    }


    return (
        <div className="password">
            {/* Disenio Anterior [NO BORRAR] <Row gutter={24} className="mb-20">
                <Col xs={24}>
                    <h1>Ingresa tus datos</h1>
                    <h6>Estos son datos para crear tu cuenta y solicitar tu cupo de crédito</h6>
                </Col>
            </Row> */}
            <Row gutter={24} className="mt-20">
                <Col xs={24}>
                    <h2 style={{ fontWeight: '600', textAlign: 'left' }}>Crea una contraseña</h2>
                    {/* Disenio Anterior [NO BORRAR] <p>Crea una contraseña para acceder a la plataforma</p> */}
                </Col>
            </Row>
            <div className="formDatos">
                <Row gutter={24} className="mt-20">
                    <Col md={12} xs={24}>
                        <div className="formPersonal">
                            <label>Contraseña</label>
                            <Input type="password" value={props.formData.password} onCopy={(e) => { e.preventDefault(); return false; }} className="input-width" onChange={e => handleChange(e, 'password')} />
                            {msg['password_error']}
                        </div>
                    </Col>
                    <Col md={12} xs={24}>
                        <div className="formPersonal">
                            <label>Confirmar Contraseña</label>
                            <Input type="password" value={props.formData.password2} onPaste={(e) => { e.preventDefault(); return false; }} className="input-width" onChange={e => handleCompare(e, 'password')} />
                            {msg["password"]}
                        </div>
                    </Col>
                </Row>
                <br />
            </div>
        </div>
    );
}

export default Password;