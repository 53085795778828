import React, { useEffect, useState, useContext } from "react";

// context 
import { AppContext } from "../../../context/AppContext";

// Services 
import registerServices from '../../../services/login/RegisterServices';

//style
import './Sign.css';

// components Antd
import { Row, Col, Input, Modal, Button } from 'antd';
import customerService from "./../../../services/customer/CustomerServices";

const Sign = (props) => {
    const [countdown, setCountdown] = React.useState(60);
    const [visibleDescuentoModal, setVisibleDescuentoModal] = useState(false);
    const [autorizacionDescuento, setAutorizacionDescuento] = useState("");

    const { setLoader } = useContext(AppContext);


    useEffect(() => {
        sendCodes(false);
        props.setFormData({ ...props.formData, sended: false });
    }, []);

    const sendCodes = (isCall) => {
        if (props.formData.phoneNumber.length === 0 || typeof props.formData.phoneNumber === 'undefined') {
            Modal.error({ title: "Error", content: "Por favor regresa e ingresa tu teléfono" });
            return false;
        }

        if (props.formData.email.length === 0 || typeof props.formData.email === 'undefined') {
            Modal.error({ title: "Error", content: "Por favor regresa e ingresa tu correo" });
            return false;
        }

        if (props.formData.email.identificationId === 0 || typeof props.formData.identificationId === 'undefined') {
            Modal.error({ title: "Error", content: "Por favor regresa e ingresa tu cédula" });
            return false;
        }


        if (props.formData.email.name === 0 || typeof props.formData.name === 'undefined') {
            Modal.error({ title: "Error", content: "Por favor regresa e ingresa tu nombre" });
            return false;
        }

        if (props.formData.email.lastName === 0 || typeof props.formData.lastName === 'undefined') {
            Modal.error({ title: "Error", content: "Por favor regresa e ingresa tu apellido" });
            return false;
        }



        setLoader(true)
        registerServices.sendCodes(props.formData, isCall).then(response => {
            // if (response.status === 200) {
            if (isCall) {
                Modal.success({ title: "Códigos Enviados", content: "Te estamos llamando para decirte el código del celular, el del correo sigue llegando a tu correo electrónico." });
            } else {
                Modal.success({ title: "Códigos Enviados", content: "Se han enviado los códigos de verificación a tu celular y a tu correo" });
            }

            props.setFormData({ ...props.formData, sended: true, code_hash: response.data.code_hash });
            setLoader(false)
            setCountdown(59);
            // } 
        }).catch(error => {
            Modal.error({ title: "Error", content: "No se pudo enviar los códigos de verificación" });
            props.setFormData({ ...props.formData, sended: false });
            setLoader(false)
            console.log(error);
        });


    }


    const startCountdown = () => {
        if (countdown > 0) {
            setTimeout(() => {
                setCountdown((countdown > 0 && countdown < 60) ? countdown - 1 : 0);
            }, 1000);
        }
    }

    useEffect(() => {
        if (countdown > 0 && countdown < 60) {
            startCountdown();
        }
    }, [countdown]);




    const changeCodes = (e, tipo) => {
        props.setFormData({ ...props.formData, ['code' + tipo]: e.target.value });
    }

    const checkCodes = () => {

        if (props.formData.codeCorreo.length === 0 || typeof props.formData.codeCorreo === 'undefined') {
            Modal.error({ title: "Error", content: "Por favor ingresa el código de verificaciónque te llegó al correo" });
            return false;
        }

        if (props.formData.codeCelular.length === 0 || typeof props.formData.codeCelular === 'undefined') {
            Modal.error({ title: "Error", content: "Por favor ingresa el código de verificación que te llegó al celular" });
            return false;
        }
        setLoader(true)
        registerServices.checkCodes(props.formData).then(response => {
            // if (response.status === 200) {
            Modal.success({ title: "Códigos Correctos", content: "Los códigos de verificación son correctos" });
            props.setFormData({ ...props.formData, checked: true, idCode: response.data.idCode });
            // } 
            // else {
            //     Modal.error({ title: "Error", content: "Los códigos de verificación no son correctos" });
            //     props.setFormData({ ...props.formData, checked: false });
            // }
            setLoader(false)

            let datefirstpayment = new Date(props.formData.datesList[0].date);
            let monthfirstpayment = datefirstpayment.getMonth();
            let aniofirstpayment = datefirstpayment.getFullYear();
            let data = {
                quantity: props.formData.monto,
                split: props.formData.split,
                totalValue: props.formData.totalValue,
                loanData: props.formData.datesList[0].quantity,
                name: props.formData.name,
                lastName: props.formData.lastName,
                identificationid: props.formData.identificationId,
                doty_id: props.formData.doty_id,
                companysocialreason: props.formData.companyName,
                companyid: props.formData.companyId,
                monthfirstpayment,
                aniofirstpayment,
            }

            customerService.getAuthorization(data).then(res => {
                console.log(res)
                setVisibleDescuentoModal(true)
                setAutorizacionDescuento(res.data.content)
            }).catch(e => {
                Modal.error({ title: "Error", content: "Error al mostrar la autorizacion de descuento" });
                console.log(e)
            })
        }).catch(error => {
            // if (error.response.status === 400) {
            Modal.error({ title: "Error", content: "Los códigos de verificación no son correctos" });
            props.setFormData({ ...props.formData, checked: false });
            // }
            setLoader(false)
            console.log(error);
        });
    }


    const changeCheckbox = (e) => {
        props.setFormData({ ...props.formData, [e.target.name]: e.target.checked });
    }


    return (
        <div className="password">

            <Row gutter={24} className="mt-20">
                <Col md={12} xs={24}>
                    <h2 style={{ fontWeight: '600', textAlign: 'left' }}>Firma Electrónica</h2>
                    <p>Ingresa los códigos que te enviamos al correo y al celular </p>
                </Col>
            </Row>
            {props.formData.checked ?
                <div className="formDatos">
                    <Row gutter={24} className="mt-20">
                        <Col md={24} xs={24} className="text-center">
                            <b>El proceso de firma digital fue exitoso</b> <br />
                        </Col>
                    </Row>
                </div>
                :
                <div className="formDatos">
                    {props.formData.sended ?
                        <Row gutter={24} className="mt-20">
                            <Col md={12} xs={24}>
                                <div className="formPersonal">
                                    <label>Código Correo</label>
                                    <Input type="text" onChange={(e) => changeCodes(e, 'Correo')} className="input-width" />
                                </div>
                            </Col>
                            <Col md={12} xs={24}>
                                <div className="formPersonal">
                                    <label>Código Celular</label>
                                    <Input type="text" className="input-width" onChange={(e) => changeCodes(e, 'Celular')} />
                                </div>
                            </Col>
                            <Col md={countdown > 0 ? 12 : 8} xs={24} className="text-center mt-20">
                                <button className="btn-blue" type="button" onClick={() => checkCodes()}>Confirmar Códigos</button>
                            </Col>
                            {countdown === 0 ? <><Col md={8} xs={24} className="text-center mt-20">
                                <button type="button" className="btn-blue" onClick={() => sendCodes(false)}>Reenviar Códigos</button>
                            </Col>
                                <Col md={8} xs={24} className="text-center mt-20">
                                    <button type="button" className="btn-blue" onClick={() => sendCodes(true)}>Llámame</button>
                                </Col>   </> : <Col md={12} xs={24} className="text-center mt-20">Podrás solicitar los códigos nuevamente en <br /><strong>{countdown}</strong> segundos</Col>}

                        </Row>
                        :
                        <Row gutter={24} className="mt-20">
                            <Col md={24} xs={24} className="text-center">
                                <button type="button" className="btn-blue" onClick={() => sendCodes(false)}>ENVÍAME LOS CÓDIGOS</button>
                            </Col>
                        </Row>}
                </div>}

            <Row gutter={24} className="mt-20">
                <Col md={24} xs={24}>
                    <Input type="checkbox" className="checkbox" onChange={e => changeCheckbox(e)} name="terminos" /> Acepto los <a target="_blank" href="/legal/terminos" >Términos y condiciones de uso</a>
                </Col>
            </Row>
            <Row gutter={24} className="mt-20">
                <Col md={24} xs={24}>
                    <Input type="checkbox" className="checkbox" onChange={e => changeCheckbox(e)} name="politicas" /> Autorizo la <a target="_blank" href="/legal/politica">Política de tratamiento de datos</a>
                </Col>
            </Row>

            <Modal
                title={"Autorización de descuento"}
                visible={visibleDescuentoModal}
                width={600}
                onCancel={() => setVisibleDescuentoModal(false)}
                footer={
                    <Button type='primary' onClick={() => setVisibleDescuentoModal(false)}>
                        Aceptar
                    </Button>}
            >
                <div dangerouslySetInnerHTML={{ __html: autorizacionDescuento }}></div>
            </Modal>

        </div>
    );
}

export default Sign