import React from "react";

// components Antd
import { Row, Col, Input, Modal } from "antd";

// assets
import cedulaFront from "../../assets/img/cedula-front.png";
import cedulaBack from "../../assets/img/cedula-back.png";
import paymentSupport from "../../assets/img/payment-support.png";

//style
import "./Files.css";

const Files = (props) => {


    const [paymentsSupport, setPaymentsSupport] = React.useState({
        pay1: undefined,
        pay2: undefined
    });

    const onChangeFile = (e, tipo, payNum) => {
        let fileType = e.target.files;

        let arrayValidos = ["application/pdf", "image/png", "image/jpeg"];

        if (typeof fileType[0] === "undefined") {
            return false;
        }

        if (!arrayValidos.includes(fileType[0].type)) {
            e.target.value = null;
            Modal.error({
                title: "Error al realizar la acción",
                content: "El archivo debe ser un jpg, png o pdf",
            });
            return false;
        }

        let filesPay = props.formData.paymentReport;

        fileType[0]
            .slice(0, 1) // only the first byte
            .arrayBuffer() // try to read
            .then(() => {
                // success, we should be able to send that File
                if (fileType[0].size < 50000000) {
                    switch (tipo) {
                        case "cedula_front":
                            props.setFormData({
                                ...props.formData,
                                documentFront: fileType[0],
                            });
                            break;
                        case "cedula_back":
                            props.setFormData({
                                ...props.formData,
                                documentBack: fileType[0],
                            });
                            break;
                        case "payment_support":
                            // if (payNum === 1) {
                            setPaymentsSupport({ ...paymentsSupport, ["pay" + payNum]: fileType[0] })
                            if (props.formData["pay" + payNum]) {
                                filesPay[payNum - 1] = fileType[0];
                            } else {
                                filesPay.push(fileType[0])
                            }
                            console.log(filesPay)
                            // filesPay = filesPay.slice(-props.formData.numPayments)
                            props.setFormData({
                                ...props.formData,
                                ["pay" + payNum]: fileType[0],
                                paymentReport: filesPay,
                            });
                            break;
                        case "working_support":
                            props.setFormData({
                                ...props.formData,
                                workingReport: fileType[0],
                            });
                            break;
                        default:
                            break;
                    }
                } else {
                    e.target.value = null;
                    Modal.error({
                        title: "Error al cargar archivo",
                        content: "El tamaño del archivo debe ser inferior a 3MB",
                    });
                }
            })
            .catch((err) => {
                // error while reading
                e.target.value = null;
                Modal.error({
                    title: "Error al cargar archivo",
                    content:
                        "Tenemos un error al subir tus documentos en este momento, por favor intenta registrarte desde un computador.",
                });
            });
    };

    console.log("numpayments")
    console.log(props.formData.numPayments)

    console.log("payFiles")
    console.log(props.formData.paymentReport)

    return (
        <div className="files">
            {/* Disenio anterior [NO BORRAR] <Row gutter={24} className="mb-20">
                <Col xs={24}>
                    <h1>Ingresa tus datos</h1>
                    <h6>
                        Estos son datos para crear tu cuenta y solicitar tu cupo de crédito
                    </h6>
                </Col>
            </Row> */}
            <Row gutter={24} className="mt-20">
                <Col xs={24}>
                    <h2 style={{ fontWeight: '600', textAlign: 'left' }}>Documentación</h2>
                    <br />
                    <ul>
                        {props.formData.product_id === 1 && (
                            <>
                                <li>Anexar el ultimo certificado laboral</li>
                                <li>
                                    Desprendible sin conceptos únicos. No anexar un documento si
                                    solo viene el pago de tu prima
                                </li>
                                <li>
                                    Si su archivo es un PDF donde están ambos lados de su cédula, debe
                                    subirlo tanto en el botón de frontal como en el de reverso
                                </li>
                            </>
                        )}

                        {props.formData.product_id === 3 && (
                            <li>Si su archivo es un PDF donde están ambos lados de su cédula, debe subirlo tanto en el botón de frontal como en el de reverso</li>
                        )}
                        {/* Disenio anterior [NO BORRAR] {props.formData.product_id === 3 && (
                            <li>Anexar el ultimo certificado laboral</li>
                        )} */}
                    </ul>
                    {/* <h5>Toma una foto o escanea los documentos solicitados (deben ser legibles)</h5> */}
                </Col>
            </Row>
            <div className="formFiles">
                <div className="file-item" >
                    <img src={cedulaFront} alt="cedula-front" />
                    <p style={{ textAlign: 'center', color: '#0086FF', fontWeight: '600', marginBottom: '0' }}>Frontal</p>
                    Foto de la cédula (Frontal) Formato: JPG, PNG, PDF (Max. 2MB)
                    <label className="file-upload">
                        <Input
                            type="file"
                            placeholder="Seleccionar archivo"
                            onChange={(e) => onChangeFile(e, "cedula_front")}
                            accept="application/pdf,image/png, image/jpeg"
                        />
                        {props.formData.documentFront ? (
                            <span className="file-name">
                                {props.formData.documentFront.name}
                            </span>
                        ) : (
                            <span className="file-upload-text">Seleccionar archivo</span>
                        )}
                    </label>
                </div>
                <div className="file-item">
                    <img src={cedulaBack} alt="cedula-back" />
                    <p style={{ textAlign: 'center', color: '#0086FF', fontWeight: '600', marginBottom: '0' }}>Reverso</p>
                    Foto de la cédula (Reverso) Formato: JPG, PNG, PDF (Max. 2MB)
                    <label className="file-upload">
                        <Input
                            type="file"
                            placeholder="Seleccionar archivo"
                            onChange={(e) => onChangeFile(e, "cedula_back")}
                            accept="application/pdf,image/png, image/jpeg"
                        />
                        {props.formData.documentBack ? (
                            <span className="file-name">
                                {props.formData.documentBack.name}
                            </span>
                        ) : (
                            <span className="file-upload-text">Seleccionar archivo</span>
                        )}
                    </label>
                </div>
                <div className="file-item">
                    <img src={paymentSupport} alt="certificado laboral" style={{ marginBottom: '10px' }} />
                    Certificado laboral Formato: JPG, PNG, PDF (Max. 2MB)
                    <label className="file-upload">
                        <Input
                            type="file"
                            placeholder="Seleccionar archivo"
                            onChange={(e) => onChangeFile(e, "working_support")}
                            accept="application/pdf,image/png, image/jpeg"
                        />
                        {props.formData.workingReport ? (
                            <span className="file-name">
                                {props.formData.workingReport.name}
                            </span>
                        ) : (
                            <span className="file-upload-text">Seleccionar archivo</span>
                        )}
                    </label>
                </div>
            </div>
            <>
                {props.formData.product_id !== 3 && (
                    <div className="formFiles">
                        {props.formData.numPayments > 0 &&
                            [...Array(props.formData.numPayments)].map((el, i) => {
                                let index = i + 1;
                                return (
                                    <div className="file-item" key={i}>
                                        <img src={paymentSupport} alt="comprobante de pago" style={{ marginBottom: '10px' }} />
                                        Desprendible de nómina ({index === 1 ? "Ultimo" : (index > 2 ? "+Anterior" : "Anterior")}) Formato: JPG, PNG, PDF (Max. 2MB)
                                        <label className="file-upload">
                                            <Input
                                                type="file"
                                                placeholder="Seleccionar archivo"
                                                onChange={(e) => onChangeFile(e, "payment_support", index)}
                                                accept="application/pdf,image/png, image/jpeg"
                                            />
                                            {props.formData["pay" + index] ? (
                                                <span className="file-name">
                                                    {props.formData["pay" + index]?.name}
                                                </span>
                                            ) : (
                                                <span className="file-upload-text">Seleccionar archivo</span>
                                            )}
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                )}
            </>
        </div>
    );
};

export default Files;
