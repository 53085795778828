import React, { useContext, useEffect } from "react";

// context 
import { AppContext } from "../../../context/AppContext";

// components Antd
import { Row, Col, Input, Select, Modal } from 'antd';

// services
import registerServices from '../../../services/login/RegisterServices';

//style
import './Contact.css';


// constants
const { Option } = Select;


const Contact = (props) => {

  const [msg, setMsg] = React.useState('');
  const [departments, setDepartments] = React.useState([]);
  const [states, setStates] = React.useState([]);

  const { setLoader } = useContext(AppContext);

  useEffect(() => {
    if (typeof props.formData.departmentId !== 'undefined') {
      setLoader(true);
      registerServices.getStates(props.formData.departmentId).then(response => {

        setStates(response.data);
        setLoader(false);
      }).catch(err => {
        console.log(err);
        setLoader(false);
      });
    }
  }, [props.formData.departmentId]);

  useEffect(() => {
    if (localStorage.basicInfo) {
      try {
        const { phone_number } = JSON.parse(localStorage.basicInfo);
        props.setFormData({ ...props.formData, phoneNumber: phone_number })

      } catch (err) {
        console.log(err)
        console.log("Error capturando la informacion basica del usuario")
      }
    }
  }, []);

  const handleCompare = (value, name) => {
    console.log(name)
    console.log(value)
    if (props.formData[name] !== value.target.value) {
      setMsg({ ...msg, [name]: "Este campo no coincide con el anterior" });
      let varCompare = "compare" + name
      props.setFormData({ ...props.formData, [varCompare]: false, [name + "2"]: value.target.value });
    } else {
      setMsg({ ...msg, [name]: "" });
      let varCompare = "compare" + name
      props.setFormData({ ...props.formData, [varCompare]: true, [name + "2"]: value.target.value });
    }
  }

  const handleChange = (value, name) => {
    if (typeof value.target === "undefined") {
      if (isNaN(value)) {
        if (value.split("||").length > 1) {
          let secondValue = (value.split("||")[1]).split(":")[1];
          let secondValueName = (value.split("||")[1]).split(":")[0];
          props.setFormData({ ...props.formData, [name]: value.split("||")[0], [secondValueName]: secondValue });
        } else {
          props.setFormData({ ...props.formData, [name]: value });
        }
      } else {
        props.setFormData({ ...props.formData, [name]: value });
      }
    } else {
      props.setFormData({ ...props.formData, [name]: value.target.value });
    }

    let arrayToCompare = ["email", "phoneNumber"];

    if (arrayToCompare.includes(name)) {
      if (props.formData[name + "2"] !== value.target.value && props.formData[name + "2"] !== "" && typeof props.formData[name + "2"] !== "undefined") {
        setMsg({ ...msg, [name]: "Este campo no coincide con el anterior" });
        let varCompare = "compare" + name + "2"
        props.setFormData({ ...props.formData, [varCompare]: false, [name]: value.target.value });
      } else {
        setMsg({ ...msg, [name]: "" });
        let varCompare = "compare" + name + "2"
        props.setFormData({ ...props.formData, [varCompare]: true, [name]: value.target.value });
      }
    }
  }

  const validationEmail = (e) => {

    const data = e.target.value
    if (data.length > 0) {

      if (data.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        setLoader(true)
        registerServices.checkEmail(data)
          .then(response => {
            if (response.data) {
              Modal.error({ title: "Error", content: "Este correo electrónico ya fue registrado" });
              props.setFormData({ ...props.formData, email: "" });
            }
            setLoader(false)
          }).catch(err => {
            console.log(err);
            setLoader(false)
          });
      } else {
        Modal.error({ title: "Error", content: "El correo electrónico no es válido" });
        props.setFormData({ ...props.formData, email: "" });
      }

    }
  }


  const validationPhone = (e) => {
    const data = e.target.value
    if (data.length > 0) {

      if (data.match(/3[0-9]{9}/)) {
        setLoader(true)
        registerServices.checkPhone(data)
          .then(response => {
            if (response.data) {
              Modal.error({ title: "Error", content: "Este número de teléfono ya fue registrado" });
              props.setFormData({ ...props.formData, phoneNumber: "" });
            }
            setLoader(false)
          }).catch(err => {
            console.log(err);
            setLoader(false)
          });
      } else {
        Modal.error({ title: "Error", content: "El número de teléfono no es válido" });
        props.setFormData({ ...props.formData, phoneNumber: "" });
      }
    }
  }

  const getDepartments = () => {
    setLoader(true);
    registerServices.getDepartments().then(response => {
      setDepartments(response.data);
      setLoader(false);
    }).catch(err => {
      console.log(err);
      setLoader(false);
    });
  }

  const getStates = (e) => {
    props.setFormData({ ...props.formData, departmentId: e });
    /* registerServices.getStates(e).then(response => {
         setStates(response.data);
     }).catch(err => {
         console.log(err);
     });*/
  }

  useEffect(() => {
    getDepartments();
  }, []);



  return (
    <div className="contact">
      {/* DISENIO ANTERIOR [NO BORRAR] <Row gutter={24} className="mb-20">
        <Col xs={24}>
          <h1>Ingresa tus datos</h1>
          <h6>Estos son datos para crear tu cuenta y solicitar tu cupo de crédito</h6>
        </Col>
      </Row> */}
      <Row gutter={24} className="mt-20">
        <Col xs={24}>
          <h2 style={{ fontWeight: '600', textAlign: 'left' }}>Datos de contacto</h2>
        </Col>
      </Row>
      <div className="formDatos">
        <Row gutter={24} className="mt-20">
          <Col md={12} xs={24}>
            <div className="formPersonal">
              <label>Correo Electrónico</label>
              <Input
                type="email"
                className="input-width"
                value={(props.formData.email) ? props.formData.email : null}
                onChange={e => handleChange(e, 'email')}
                onBlur={(e) => validationEmail(e)}
                disabled={props.formData.email}
              />
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className="formPersonal">
              <label>Confirmar Correo</label>
              <Input type="email" className="input-width" value={(props.formData.email2) ? props.formData.email2 : null} onChange={e => handleCompare(e, 'email')} />
              {msg['email']}
            </div>
          </Col>
        </Row>
        <Row gutter={24} className="mt-20">
          <Col md={12} xs={24}>
            <div className="formPersonal">
              <label>Número de celular</label>
              <Input
                className="input-width"
                value={(props.formData.phoneNumber) ? props.formData.phoneNumber : null}
                onChange={e => handleChange(e, 'phoneNumber')}
                onBlur={(e) => validationPhone(e)}
                disabled={props.formData.phoneNumber}
                maxLength={10}
              />
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className="formPersonal">
              <label>Confirmar número de celular</label>
              <Input
                className="input-width"
                value={(props.formData.phoneNumber2) ? props.formData.phoneNumber2 : null}
                onChange={e => handleCompare(e, 'phoneNumber')}
                maxLength={10}
              />
              {msg['phoneNumber']}
            </div>
          </Col>
        </Row>
        <Row gutter={24} className="mt-20">
          <Col md={12} xs={24}>
            <div className="formPersonal">
              <label>Departamento</label>
              <Select className="input-width" value={(props.formData.departmentId) ? props.formData.departmentId : null} onChange={(e) => getStates(e)} showSearch optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }>
                {departments.map((department, index) => {
                  return (
                    <Option key={index} value={department.depaId}>{department.depaName}</Option>
                  )
                })}
              </Select>
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className="formPersonal">
              <label>Ciudad</label>
              <Select className="input-width" value={(props.formData.muni_id) ? props.formData.muni_id : null} onChange={(e) => handleChange(e, 'muni_id')} showSearch optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }>
                {states.map((state, index) => {
                  return (
                    <Option key={index} value={state.muniId}>{state.muniName}</Option>
                  )
                })}
              </Select>
            </div>
          </Col>
        </Row>
        <Row gutter={24} className="mt-20">
          <Col md={12} xs={24}>
            <div className="formPersonal">
              <label>Dirección de residencia</label>
              <Input className="input-width" onChange={(e) => handleChange(e, 'clie_address')} value={(props.formData.clie_address) ? props.formData.clie_address : ""} />
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className="formPersonal">
              <label>Interior / Apto / Casa </label>
              <Input className="input-width" onChange={(e) => handleChange(e, 'clie_address2')} value={(props.formData.clie_address2) ? props.formData.clie_address2 : ""} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contact;