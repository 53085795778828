import React from "react";

const Home = () => {

    return(
        <div>            
            dashboard
        </div>
    );
    
}

export default Home;