import axios from 'axios';
import BaseUrl from '../BaseUrl';

const updateNewRegister = (data) => {

  let urlString = `${BaseUrl}Account/UpdateNewRegister`;

  var bodyFormData = new FormData();

  bodyFormData.append('identificationId', data.identificationId);
  bodyFormData.append('company', data.company);
  bodyFormData.append('client_id', data.client_id);

  if (data.archivos) {
    data.archivos.forEach(function (el) {
      let files = data[el];
      console.log(files);
      for (const key in files) {
        if (Object.hasOwnProperty.call(files, key)) {
          bodyFormData.append(el, files[key]);
        }
      }
    })
  }

  const config = {
    url: urlString,
    method: 'POST',
    data: bodyFormData,
  };

  return axios(config)

}

const addPhotoValidate = (data) => {

  let urlString = `${BaseUrl}Account/AddPhotoValidate`;

  var bodyFormData = new FormData();

  bodyFormData.append('identificationId', data.identificationId);
  bodyFormData.append('company', data.company);
  bodyFormData.append('client_id', data.client_id);
  bodyFormData.append('fileValidate', data.fileValidate);

  const config = {
    url: urlString,
    method: 'POST',
    data: bodyFormData,
  };

  return axios(config)

}

const requestUpdateDocuments = (data) => {

  let urlString = `${BaseUrl}Request/UpdateDocuments`;

  var bodyFormData = new FormData();

  bodyFormData.append('identificationId', data.identificationId);
  bodyFormData.append('idCompany', data.company);
  bodyFormData.append('idRequest', data.idRequest);
  bodyFormData.append('workingSupport_rejected', data.workingSupport_rejected);
  bodyFormData.append('paymentSupport_rejected', data.paymentSupport_rejected);





  if (data.paymentSupport) {
    data.paymentSupport.forEach(function (el) {
      console.log(el)
      bodyFormData.append("paymentSupport", el);
    })
  }

  if (data.workingSupport) {
    data.workingSupport.forEach(function (el) {
      bodyFormData.append("workingSupport", el);
    })
  }



  const config = {
    url: urlString,
    method: 'POST',
    data: bodyFormData,
    headers: {
      'Authorization': 'Bearer ' + data.token
    }
  };

  return axios(config)

}

const validatorWithIdentification = (identificationid) => {

  let urlString = `${BaseUrl}Customer/ValidatorWhitIdentification`;

  const config = {
    url: urlString,
    method: 'GET',
    headers: {
      identificationid,
    }
  };

  return axios(config)

}

const getAuthorization = (data) => {

  let { quantity, split, totalValue, loanData, name, lastName, identificationid, doty_id, companysocialreason, monthfirstpayment, aniofirstpayment } = data
  let urlString = `${BaseUrl}Request/PreviewAutorizationFullRecord`;

  const config = {
    url: urlString,
    method: 'GET',
    headers: {
      quantity,
      split,
      totalValue,
      loanData,
      name,
      lastName,
      identificationid,
      doty_id,
      companysocialreason,
      monthfirstpayment,
      aniofirstpayment,
    }
  };

  return axios(config)

}

const customerService = { updateNewRegister, addPhotoValidate, requestUpdateDocuments, validatorWithIdentification, getAuthorization };
export default customerService;