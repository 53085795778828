import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";


/* Framework */
import { Modal, Row, Col } from 'antd';

/* CSS */
import './UploadRequestDocuments.css';


/* Services */
import customerService from "../../../services/customer/CustomerServices";


const UploadRequestDocuments = (props) => {

    let history = useHistory();

    const [params, setParams] = useState(null);
    const [descripcion, setDescripcion] = useState(null);
    const [sended, setSended] = useState(false);
    const [paymentSupport, setPaymentSupport] = useState([]);
    const [workingSupport, setWorkingSupport] = useState([]);



    const loadReasons = () => {

        let rere_array = (props.objJWT.listRejectionReasons);

        let params = [];
        let descripcion = [];
        rere_array.forEach(function (el) {
            let _tratado = (el.rere_description).replace("Hola, ", "").replace(" Avanzo te presta TEL 4824824 ", "");
            if (descripcion.indexOf(_tratado) === -1) {
                descripcion.push(_tratado);
            }
            if (el.rere_param) {
                if (props.objJWT.paymentSupport_rejected !== undefined && props.objJWT.paymentSupport_rejected !== null && props.objJWT.paymentSupport_rejected.length > 0) {
                    let cantPay = (props.objJWT.paymentSupport_rejected).split(",");
                    for (let i = 0; i < cantPay.length; i++) {
                        params.push({ label: "Desprendible de nómina", name: "paymentSupport" });
                    }
                }

                if (props.objJWT.workingSupport_rejected !== undefined && props.objJWT.workingSupport_rejected !== null && props.objJWT.workingSupport_rejected.length > 0) {
                    let cantWork = (props.objJWT.workingSupport_rejected).split(",");
                    for (let i = 0; i < cantWork.length; i++) {
                        params.push({ label: "Certificación laboral", name: "workingSupport" });
                    }
                }
            }
        });

        setParams(params);
        setDescripcion(descripcion);

    }

    useEffect(() => {
        loadReasons();
    }, []);

    const onChangeFile = (e) => {
        let name = e.target.getAttribute("name");
        let value = e.target.files[0];

        if (name === "paymentSupport") {
            let _array = (paymentSupport) ? paymentSupport : [];
            _array.push(value);
            setPaymentSupport(_array);
        } else if (name === "workingSupport") {
            let _array = (workingSupport) ? workingSupport : [];
            _array.push(value);
            setWorkingSupport(_array);
        }
    }



    const updateNew = () => {


        if (!paymentSupport.length && !workingSupport.length) {
            Modal.error({ title: "Error", content: "Debe seleccionar al menos un archivo" });
            return false;
        }

        let data = {
            identificationId: props.objJWT.identificationId,
            company: props.objJWT.company,
            idRequest: props.objJWT.requestid,
            workingSupport_rejected: props.objJWT.workingSupport_rejected,
            paymentSupport_rejected: props.objJWT.paymentSupport_rejected,
            token: props.token,
            paymentSupport: paymentSupport,
            workingSupport: workingSupport
        }


        customerService.requestUpdateDocuments(data).then(response => {
            if (response.status === 200) {
                Modal.success({ title: "Acción realizada exitosamente", content: response.data.message });
                setSended(true);
            } else {
                Modal.error({ title: 'Error al cargar archivos.', content: response.data });
            }
        }).catch(err => {
            console.log(err);
            console.log(err.response)
            if (err.response.status === 403) {
                Modal.error({ title: 'Error', content: 'Lo sentimos, ya no puedes realizar esta acción, el enlace venció, intenta realizar nuevamente la solicitud de crédito.' })
            }
        });
    }



    return (<div>

        {!descripcion && <div>Cargando...</div>}

        {descripcion && <div>
            {
                !sended && <div>
                    <Row className="all-margin">
                        <Col xxl={24} lg={24} md={24} sm={24} xs={24} ><br /><br />
                            <h3 className="text-center">No hemos podido procesar tu solicitud correctamente <br />porque la solicitud que enviaste tiene algunos problemas,<br />te contamos a continuación.</h3>  </Col>
                    </Row>
                    <Row gutter={24} className="all-margin">
                        <Col xxl={24} lg={24} md={24} sm={24} xs={24} ><br /><br />
                            <h4 className="text-center">{descripcion.map((el, i) => (
                                <li key={i} className={"li-razon"}>{el.charAt(0).toUpperCase() + el.slice(1)}</li>
                            ))}</h4>

                            {
                                params.map((el, i) => (
                                    <label key={i} className="labelUpload">{(typeof el.name.split("/")[1] !== "undefined") ? el.name.split("/")[1] : el.label.replace(" no válido", "").replace(" no válida", "")}:  <input
                                        id={"file" + i}
                                        name={(typeof el.name.split("/")[1] !== "undefined") ? el.name.split("/")[0] : el.name}
                                        type="file"
                                        onChange={(e) => onChangeFile(e)}
                                    /></label>
                                ))
                            }
                        </Col>
                    </Row>
                    <br />
                    {
                        params.length > 0 && <Row className="all-margin">
                            <Col xxl={24} lg={24} md={24} sm={24} xs={24} style={{ textAlign: "center" }} ><br />
                                <button className="btn-blue" onClick={() => updateNew()}>Enviar archivos</button>
                            </Col>
                            <br />
                        </Row>
                    }
                </div>
            }
            {
                sended && <div>
                    <Row className="all-margin">
                        <Col xxl={24} lg={24} md={24} sm={24} xs={24} style={{ textAlign: "center" }}><br /><br />
                            <h3 className="text-center">Hemos recibido los documentos que enviaste, los revisaremos y estaremos en contacto contigo.</h3> <br /><br />
                            <button className="btn-blue" onClick={() => { history.push("/") }}>Ir al Inicio</button>  </Col>
                    </Row>
                </div>
            }

        </div>}

    </div>);

}

export default UploadRequestDocuments;