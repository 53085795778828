import React, { useEffect } from "react";

// antd components 
import { Row, Col, Select, Modal, Input, Divider, Form } from 'antd';

// Styles
import './Soat.css';

// services 
import generalServices from '../../../../services/general/GeneralServices';


const Soat = (props) =>{

    const [tiposVehiculos, setTiposVehiculos] = React.useState([]);
    const [soatRates, setSoatRates] = React.useState([]);
    const [antiguedadVehiculos, setAntiguedadVehiculos] = React.useState([]);
    const [subtiposVehiculos, setSubtiposVehiculos] = React.useState([]);

    const [form] = Form.useForm();

    const { Option } = Select;
    const getAllRatesSoat = () =>{
    
        generalServices.getAllRatesSoat().then(res => {
            let rates = res.data;
            let tiposVehiculos = [];
            tiposVehiculos["subtipos"] = [];
            let typeVehicle = null;
            rates.forEach((el, i) => {
              if (tiposVehiculos[el.idSoatVehiculos] !== undefined) {
                tiposVehiculos[el.idSoatVehiculos]["nombre"] = el.nombreVehiculo;
                if (localStorage.vehicle) {
                  if (localStorage.vehicle_type === el.nombreVehiculo) {
                    typeVehicle = el.idSoatVehiculos;
                  }
                }
                if (tiposVehiculos[el.idSoatVehiculos]["subtipos"] !== undefined) {
                  tiposVehiculos[el.idSoatVehiculos]["subtipos"][el.idSoatSubtipos] = el.nombreSubtipo;
                } else {
                  tiposVehiculos[el.idSoatVehiculos]["subtipos"] = [];
                  tiposVehiculos[el.idSoatVehiculos]["subtipos"][el.idSoatSubtipos] = el.nombreSubtipo;
                }
    
              } else {
                tiposVehiculos[el.idSoatVehiculos] = [];
                tiposVehiculos[el.idSoatVehiculos]["nombre"] = el.nombreVehiculo;
                if (localStorage.vehicle) {
                  if (localStorage.vehicle_type === el.nombreVehiculo) {
                    typeVehicle = el.idSoatVehiculos;
                  }
                }
                if (tiposVehiculos[el.idSoatVehiculos]["subtipos"] !== undefined) {
                  tiposVehiculos[el.idSoatVehiculos]["subtipos"][el.idSoatSubtipos] = el.nombreSubtipo;
                } else {
                  tiposVehiculos[el.idSoatVehiculos]["subtipos"] = [];
                  tiposVehiculos[el.idSoatVehiculos]["subtipos"][el.idSoatSubtipos] = el.nombreSubtipo;
                }
              }
            });
    
            setTiposVehiculos(tiposVehiculos);
            setSoatRates(rates);
            /*setTypeVehicle(typeVehicle);
            
            if (typeVehicle) {
              this.setTipo(typeVehicle);
            }
            */
            //this.getInitialValues();
          }).catch(err => {
            console.log(err);
          });

    }

    useEffect(() => {
      getAllRatesSoat();      
    }, []);

   
    const setTypeVehicle = (tipo) =>{
      
        let listaSubtipos = [];
        /* Inicializo las variables */
        props.setFormData({...props.formData, vehicle_subtype: null, vehicle_old:null, vehicle_old_name:"", monto:0});
        form.setFieldsValue({
            vehicle_type: tipo,
            vehicle_subtype: null,
            vehicle_old:null,
        });
        
        /* Recorro tipos de vehiculos para saber cuales tienen subtipos */
        tiposVehiculos.forEach((el, i) => {
          if (i === tipo) {
            props.setFormData({...props.formData, vehicle_type_name: el.nombre, vehicle_type: tipo});
            listaSubtipos = el.subtipos;
          }
        });
        /* Seteo los subtipos */
        if (listaSubtipos.length > 0) {
            setSubtiposVehiculos(listaSubtipos);
        } else {
            setSubtiposVehiculos([]);
            /* Como no existen subtipos obtengo el precio del soat*/
            
            getPrecio(tipo);   
            
             
        }
    }

    const setSubtypeVehicle = (subtipo) => {
     
      let listaAntiguedad = [];
       /* Inicializo las variables */
       props.setFormData({...props.formData, vehicle_old: null, vehicle_old_name:"", monto: 0});

       form.setFieldsValue({
        vehicle_subtype: subtipo,
        vehicle_old:null,
      });
       
      soatRates.forEach((el, i) => {
        if (el.idSoatSubtipos === subtipo) {
          props.setFormData({...props.formData, vehicle_subtype: subtipo,vehicle_subtype_name: el.nombreSubtipo});
          /* Compruebo que exista una antiguedad */
          if (el.antiguedad) {

            setAntiguedadVehiculos([])
            
            /* En caso de que exista index de antiguedad construyo la lista para el select  */
            let texto = "";
            if (el.antiguedad === 9) {
              texto = "0 a 9 años";
            } else {
              if (el.antiguedad === 10) {
                texto = "10 años o más";
              }
            }
            listaAntiguedad.push({value: el.antiguedad , label: texto } );
          }
        }
      });
  
      /* Seteo la antiguedad */
      if (listaAntiguedad.length > 0) {
        setAntiguedadVehiculos(listaAntiguedad);
       
      } else {
        setAntiguedadVehiculos([]);
        /* Como no existen antiguedad obtengo el precio del soat*/
        
        getPrecio(null, subtipo);  
        
        
        
      }
  
    }

    const setOldVehicle = (ant) => {

        
      form.setFieldsValue({
        vehicle_old:ant,
      });
      
      let antNombre = "";
      antiguedadVehiculos.forEach((el, i) => {

        if (el.value === ant) {
          antNombre = el.label;
        }
      })
      
      props.setFormData({...props.formData, vehicle_old: ant, vehicle_old_name: antNombre, monto:0});

      let finalData = {
        vehicle_old: ant,
        vehicle_old_name: antNombre,
      }

      getPrecio(null, null, ant, finalData);    

       
  
    }

    const getPrecio = (tipo,subtipo, antiguedad, finalData) =>{
        tipo = tipo || props.formData.vehicle_type;
        subtipo = subtipo || props.formData.vehicle_subtype;
        antiguedad = antiguedad || props.formData.vehicle_old;

        if (tipo !== null && tipo > 0) {
          if (subtipo !== null && subtipo > 0) {
            if (antiguedad !== null && antiguedad > 0) {
                soatRates.forEach((el, i) => {
                    if (el.idSoatVehiculos === tipo && el.idSoatSubtipos === subtipo && el.antiguedad === antiguedad) {
                      if(finalData){
                        props.setFormData({...props.formData, monto: el.precio , vehicle_old: finalData.vehicle_old, vehicle_old_name: finalData.vehicle_old_name});
                      }else{
                        props.setFormData({...props.formData, monto: el.precio});
                      }
                        /*if (el.precio > this.state.partialCapacity) {
                            this.setState({
                            request_overdraft: true,
                            request_observation: 4
                            })
                        } else {
                            this.setState({
                            request_overdraft: false,
                            request_observation: ""
                            })
                        }*/
                        
                        //this.getLastValues(el.precio);
                    }
                });
            } else {

            soatRates.forEach((el, i) => {
                if (el.idSoatVehiculos === tipo && el.idSoatSubtipos === subtipo) {
                    props.setFormData({...props.formData, monto: el.precio});
               /* if (el.precio > this.state.partialCapacity) {
                    this.setState({
                    request_overdraft: true,
                    request_observation: 4
                    })
                } else {
                    this.setState({
                    request_overdraft: false,
                    request_observation: ""
                    })
                }
                
                this.getLastValues(el.precio);*/
                }
            });
            }

        } else {

            soatRates.forEach((el, i) => {
            if (el.idSoatVehiculos === tipo) {
                props.setFormData({...props.formData, monto: el.precio});
                /*if (el.precio > this.state.partialCapacity) {
                this.setState({
                    request_overdraft: true,
                    request_observation: 4
                })
                } else {
                this.setState({
                    request_overdraft: false,
                    request_observation: ""
                })
                }
                
                this.getLastValues(el.precio);
                */
            }
            });
        }
        } else {
            Modal.error({title:"Error", content: "No es posible cotizar sin tipo de vehículo"});
        }

    }

    

    return(
        <Form form={form} className="soat">
            <Row gutter={24}>
                <Col md={16} xs={24}>
                    <div className="formSoat">
                    <label>Tipo de vehículo</label>
                    <Form.Item name="vehicle_type" rules={[{ required: true, message: 'Seleccione un tipo de vehículo' }]} className="input-width">
                      <Select onChange={e => setTypeVehicle(e)} placeholder="Selecciona" showSearch optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }>
                      {
                          tiposVehiculos.map((el, i) => {
                              return(
                                  <Option key={i} value={i}>{el.nombre}</Option>
                              )
                          })
                      }
                      </Select>
                        </Form.Item>              
                    </div>
                </Col>
                <Col md={8} xs={24}>
                    <div className="formSoat">
                        <label>Placa</label>
                        <Input onChange={e => props.setFormData({...props.formData, vehicle_plate: e.target.value})}  style={{width:"150px", maxWidth:"100%"}} />  
                    </div>
                </Col>
            </Row>
            {
                subtiposVehiculos.length > 0  && 
                <Row gutter={24}>
                <Col md={12} xs={24}>
                    <div className="formSoat">
                    <label>Subtipo de vehículo</label>
                    <Form.Item name="vehicle_subtype" rules={[{ required: true, message: 'Seleccione un subtipo de vehículo' }]} className="input-width">
                    <Select onChange={e => setSubtypeVehicle(e)}  placeholder="Selecciona" showSearch optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }>
                        {
                            subtiposVehiculos.map((el, i) => {
                                return(
                                    <Option key={i} value={i}>{el}</Option>
                                )
                            })
                        }
                    </Select>
                    </Form.Item>
                    </div>
                </Col>
                <Col md={12} xs={24}>
                  {
                    antiguedadVehiculos.length > 0 &&
                    <div className="formSoat">
                    <label>Antiguedad</label>
                   
                    <Form.Item name="vehicle_old" rules={[{required:true, message:"Selecciona la antiguedad"}]} className="input-width">               
                    <Select onChange={e => setOldVehicle(e)} placeholder="Selecciona" showSearch optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }>
                        {
                          antiguedadVehiculos.map((el, i) => {
                                return(
                                    <Option key={i} value={el.value}>{el.label}</Option>
                                )
                            })
                        }
                    </Select>
                      
                    </Form.Item>  
                </div>
                  }
                   
                </Col>
            </Row>
            }
         
            <Divider />
        </Form>
    )
}

export default Soat;