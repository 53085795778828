import React from "react";
import {
    useHistory,
    Link
  } from "react-router-dom";
/* Framework */
import { Form, Input, Button, Modal, Row, Col  } from 'antd';

/* CSS */
import './Recovery.css';

/* Assets */
import logo from '../../../assets/img/logo.png';
import chicaavanzo from '../../../assets/img/chicaavanzo.png';

/* Services */ 
import {dorecovery} from "../../../services/login/LoginServices";


const Recovery = () => {
    const history = useHistory();

    const onFinish = (values) => {

        let body = {
            email: values.username,
         }
        dorecovery(body).then(response => {
            console.log(response);
            if(response.status === 200){
                Modal.success({
                    title: 'Recuperación de contraseña',
                    content: 'Te hemos enviado un correo con un enlace para que establezcas la nueva contraseña',
                    onOk: () => {
                        history.push('/login');
                    }
                });
            }else{
                Modal.error({
                    title: 'Recuperación de contraseña',
                    content: 'No se ha podido recuperar tu contraseña',
                    onOk: () => {
                        history.push('/login');
                    }
                });
            }

        }).catch(error => {
            console.log(error);
            Modal.error({title: 'Error', content: error.message});
        });   

        
        
    };
    
    const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    };
    
    
    return(
        <div className={"main"}>
            <Row gutter={24} className={"row-header"}>
                <Col md={24} xs={24} className={"text-center"}>
                    <img src={logo} alt="logo" className={"logo"}/>
                    Créditos al instante
                    <span className={"bottom-line"}></span>
                </Col>
            </Row>
            <Row gutter={24} className={"row-body"}>
                <Col md={12} xs={24} className={"col-form"}>
                <br/><br/>    
                <p className={"fore-gray"}>Digita tu correo electrónico y<br/> recibirás un enlace para que puedas restablecer tu contraseña.</p><br/><br/> 
                    <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    >
                       
                       <Form.Item
                            label="E-mail"
                            name="username"
                            rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'Por favor ingresa tu correo electrónico.',
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                            offset: 8,
                            span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                            Recuperar contraseña
                            </Button><br/><br/>
                            <Link to="/login" className={"ant-btn ant-btn-dashed"}>Volver atrás</Link>
                        </Form.Item>
                    </Form>
                    
                </Col>
                <Col md={12} xs={24} className={"col-chica"}>
                    <img src={chicaavanzo} alt="chicaavanzo" className={"chicaavanzo"}/>
                </Col>
            </Row>
        </div>
    );
    
}

export default Recovery;